import React, { useState } from 'react';
import styled from 'styled-components';
import useForm from '../../utils/useForm';
import { validateForm } from '../../utils/helpers';
import { submitSaveBuild } from '../../utils/api';
import { Input, InputGroup, SolidButton } from '../../styles/shared';
import { colors } from '../../styles/theme';
import { useSelector } from 'react-redux';
import { getSubmittedForms, getVisitorInfo } from '../../store/app';

const SaveBuildForm = ({ formName }) => {
  const visitorInfo = useSelector(getVisitorInfo);
  const { firstName, email } = visitorInfo;
  const formSubmitted = useSelector(getSubmittedForms).some(
    f => f === formName
  );
  const [isSubmitting, setSubmitting] = useState(false);
  const { values, handleChange, handleBlur, handleSubmit, errors } = useForm(
    formName,
    { firstName, email },
    validateForm,
    isSubmitting,
    submitting => setSubmitting(submitting),
    submitSaveBuild
  );
  const formIsValid = Object.keys(errors).length !== 0;
  return (
    <Form onSubmit={handleSubmit}>
      <InputGroup>
        <p>Email</p>
        <Input
          type='email'
          name='email'
          onChange={handleChange}
          value={values.email}
          onBlur={handleBlur}
          required
          className={!!errors.email ? 'error' : ''}
        />
        <p className='error'>{errors.email}</p>
      </InputGroup>
      <InputGroup>
        <p>First name</p>
        <Input
          type='text'
          name='firstName'
          onChange={handleChange}
          value={values.firstName}
          onBlur={handleBlur}
          className={!!errors.firstName ? 'error' : ''}
        />
        <p className='error'>{errors.firstName}</p>
      </InputGroup>
      <SolidButton type='submit' disabled={isSubmitting || formIsValid}>
        {isSubmitting ? 'Submitting' : 'Submit'}
      </SolidButton>
    </Form>
  );
};

const Form = styled.form`
  width: 100%;
  display: grid;
  & p.error {
    color: ${colors.red};
    font-size: 0.875rem;
    line-height: 1.3125rem;
    justify-self: end;
  }

  button {
    width: 100%;
  }
`;
const ThankYou = styled.div``;
export default SaveBuildForm;
