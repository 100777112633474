import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeIntent,
  getSelectedCommunity,
  getSelectedIntent,
  getSummaryData,
  getTotalDownPayment,
  getTotalPrice,
} from '../store/build';
import { formatPrice, formatPricePerMonth } from '../utils/helpers';
import { Hr } from '../styles/shared';
import { colors, devices, fontWeights } from '../styles/theme';
import { communityLotDataConfig } from '../utils/dataConfig';
import SliderToggle from './SliderToggle';

const SummaryTable = ({ isModal }) => {
  const dispatch = useDispatch();
  const summaryData = useSelector(getSummaryData);
  const totalPrice = useSelector(getTotalPrice);
  const selectedCommunity = useSelector(getSelectedCommunity);
  const selectedIntent = useSelector(getSelectedIntent);
  const totalDownPayment = useSelector(getTotalDownPayment);
  return (
    <>
      {isModal && (
        <TitleContainer>
          <Line />
          <Title>{communityLotDataConfig[selectedCommunity].title}</Title>
        </TitleContainer>
      )}
      <TableContainer>
        {summaryData.map((item, index) => (
          <React.Fragment key={item.title + index}>
            <ItemTitle>{item.title}</ItemTitle>
            <Price>{formatPricePerMonth(item.price)}</Price>
          </React.Fragment>
        ))}
        <Line />
        {!isModal && (
          <>
            <MonthlyTotalNote>Estimated Monthly Payment</MonthlyTotalNote>
            <MonthlyTotalPrice>{formatPrice(totalPrice)}</MonthlyTotalPrice>
            <DownPaymentNote>
              Estimated {selectedIntent === 'own' ? 'Down Payment' : 'Deposit'}
            </DownPaymentNote>
            <DownPaymentTotal>{formatPrice(totalDownPayment)}</DownPaymentTotal>
            <Toggle
              current={selectedIntent}
              left={{ title: 'Owner', value: 'own' }}
              right={{ title: 'Renter', value: 'rent' }}
              handleClick={() =>
                dispatch(
                  changeIntent(selectedIntent === 'own' ? 'rent' : 'own')
                )
              }
            />
          </>
        )}
        <StickyBackground />
      </TableContainer>
    </>
  );
};

const TitleContainer = styled.div`
  padding: 1rem 0 1.2rem;
  @media (${devices.s}) {
    padding: 0.75rem 0;
  }
`;
const TableContainer = styled.div`
  position: relative;
  column-gap: 0.3rem;
  display: grid;
  grid-template-columns: 1fr auto;
  //grid-auto-flow: dense;
  row-gap: 0.8rem;
  align-items: center;
  align-content: start;
  font-size: 1rem;
  line-height: 1.2rem;
  > :first-child {
    font-weight: ${fontWeights.bold};
  }
  > :nth-child(2) {
    display: none;
  }
  @media (${devices.s}) {
    line-height: 1rem;
    align-items: end;
    row-gap: 0.6rem;
    column-gap: 0.3rem;
  }
  // fine tune sticky subtotals
  --note-font-size: 1.1rem;
  --note-line-height: 1.5rem;
  --down-payment-bottom: 0;
  --monthly-payment-bottom: 2rem;
  --background-height: 5.25rem;
  --line-bottom: 5rem;
  @media (${devices.m}) {
    --monthly-payment-bottom: 2rem;
    --line-bottom: 4.5rem;
    --background-height: 4.75rem;
  }
  @media (${devices.s}) {
    --monthly-payment-bottom: 1.75rem;
    --line-bottom: 4rem;
    --background-height: 4.25rem;
    --note-font-size: 1rem;
    --note-line-height: 1rem;
  }
`;
const Title = styled.h3`
  font-weight: ${fontWeights.bold};
  font-size: 1.25rem;
  line-height: 1.5rem;
`;
const ItemTitle = styled.p`
  grid-column: 1 / 2;
`;
const Price = styled.p`
  grid-column: 2 / -1;
`;
const Line = styled(Hr)`
  margin: var(--hr-margin);
  grid-column: 1 / -1;
  position: sticky;
  bottom: var(--line-bottom);
  z-index: 2;
`;
const StickyBackground = styled.div`
  height: var(--background-height);
  grid-column: 1 / -1;
  background-color: ${colors.white};
  z-index: 1;
  position: sticky;
  bottom: 0;
  display: none;
  @media (${devices.sv}) {
    display: block;
    width: 100%;
  }
  @media (${devices.xs}) {
    display: none;
  }
`;
const MonthlyTotalNote = styled.p`
  grid-column: 1 / 2;
  font-size: var(--note-font-size);
  font-weight: ${fontWeights.medium};
  font-style: italic;
  line-height: var(--note-line-height);
  position: sticky;
  bottom: var(--monthly-payment-bottom);
  z-index: 2;
`;
const DownPaymentNote = styled(MonthlyTotalNote)`
  font-weight: ${fontWeights.regular};
  bottom: var(--down-payment-bottom);
`;

const MonthlyTotalPrice = styled.h2`
  grid-column: 2 / -1;
  font-size: var(--note-font-size);
  font-weight: ${fontWeights.medium};
  font-style: italic;
  var(--note-line-height);
  position: sticky;
  bottom: var(--monthly-payment-bottom);
  z-index: 2;

`;
const DownPaymentTotal = styled(MonthlyTotalPrice)`
  font-weight: ${fontWeights.regular};
  bottom: var(--down-payment-bottom);
`;

const Overlay = styled.div`
  height: 2.25rem;
  width: 100%;
  grid-column: span 2;
  background-color: ${colors.white};
  position: sticky;
  bottom: -1rem;
  z-index: 1;
  display: none;
  @media (${devices.s}) {
    display: block;
  }
`;
const StickyLine = styled(Line)`
  @media (${devices.s}) {
    position: sticky;
    bottom: 1.2rem;
    z-index: 3;
  }
`;
const Toggle = styled(SliderToggle)`
  grid-column: span 2;
  z-index: 2;
`;
export default SummaryTable;
