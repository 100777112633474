import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { colors, devices } from '../styles/theme';
import { IoChevronDown } from '@react-icons/all-files/io5/IoChevronDown';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeBathroomOption,
  changeOption,
  getSelectedIntent,
} from '../store/build';
import Modal from './Modal';
import { OptionSubtitle, OptionTitle } from '../styles/shared';
import {
  formatExtraPerMonth,
  getAdjustedPriceFromIntent,
} from '../utils/helpers';

const OptionsDropdown = ({
  selectedOption,
  options,
  currentArea,
  currentBathroom,
}) => {
  const selectedOptionInfo = options[selectedOption];
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const anchorEl = useRef(null);
  const [anchorStyle, setAnchorStyle] = useState(null);

  const selectedIntent = useSelector(getSelectedIntent);

  // subtitle special cases for exterior and bathroom
  const hideSubtitle = currentArea === 'exterior' || currentArea === 'bathroom';

  // work around for limiting bathtub module from downgrading
  const isBathtubModule =
    currentArea === 'bathroom' && currentBathroom.startsWith('extra-bathtub-');
  const handleClick = option => {
    open &&
      (currentArea === 'bathroom'
        ? dispatch(changeBathroomOption(currentBathroom, option))
        : dispatch(changeOption(currentArea, option)));
    if (!isBathtubModule) {
      const { left, top, width } = anchorEl.current?.getBoundingClientRect();
      setAnchorStyle({ left, top, width });
      setOpen(prev => !prev);
    }
  };
  return (
    selectedOption && (
      <Container isHidden={open}>
        <DefaultOption ref={anchorEl} onClick={handleClick}>
          <OptionTitle>{selectedOptionInfo.title}</OptionTitle>
          {!hideSubtitle && (
            <OptionSubtitle
              highlight={selectedOptionInfo.subtitle?.startsWith('available')}
            >
              {selectedOptionInfo.subtitle ||
                formatExtraPerMonth(
                  currentArea === 'bathroom'
                    ? isBathtubModule
                      ? 0
                      : getAdjustedPriceFromIntent(
                          selectedOptionInfo.inTemplatePrice,
                          selectedIntent
                        )
                    : getAdjustedPriceFromIntent(
                        selectedOptionInfo.price,
                        selectedIntent
                      )
                )}
            </OptionSubtitle>
          )}
          {!isBathtubModule && <ChevronDown size='1.25rem' />}
        </DefaultOption>
        {open && (
          <Modal anchorStyle={anchorStyle} handleClose={() => setOpen(false)}>
            <OptionList>
              {[
                // Re-order dropdown so selected option is on top
                selectedOption,
                ...options.list.filter(item => item !== selectedOption),
              ].map(option => (
                <Option key={option} onClick={() => handleClick(option)}>
                  <OptionTitle>{options[option].title}</OptionTitle>
                  {!hideSubtitle && (
                    <OptionSubtitle>
                      {options[option].subtitle ||
                        formatExtraPerMonth(
                          currentArea === 'bathroom'
                            ? getAdjustedPriceFromIntent(
                                options[option].inTemplatePrice,
                                selectedIntent
                              )
                            : getAdjustedPriceFromIntent(
                                options[option].price,
                                selectedIntent
                              )
                        )}
                    </OptionSubtitle>
                  )}
                </Option>
              ))}
            </OptionList>
          </Modal>
        )}
      </Container>
    )
  );
};
const Container = styled.div`
  width: 100%;
  justify-self: stretch;
  border: 1px solid ${colors.gray};

  &:hover {
    border-color: ${colors.black};
  }

  border-radius: 0.375rem;
  line-height: 2rem;
  display: grid;
  align-content: center;
  position: relative;
  visibility: ${props => (props.isHidden ? 'hidden' : 'visible')};
`;
const Option = styled.button`
  padding: var(--option-padding);
  width: 100%;
  background-color: ${colors.white};
  display: grid;
  justify-items: start;
  row-gap: 0.7rem;
  position: relative;
  border-radius: 0.375rem;
  @media (${devices.s}) {
    row-gap: 0.4rem;
  }
`;
const DefaultOption = styled(Option)`
  grid-area: 1 / 1;
  background-color: transparent;
`;
const OptionList = styled.ul`
  grid-area: 1 / 1;
  position: absolute;
  top: 0;
  width: 100%;
  border: 1px solid ${colors.black};
  border-radius: 0.375rem;
  background-color: ${colors.white};

  > :first-child {
    background-color: ${colors.lighter};
  }

  > *:hover {
    color: ${colors.white};
    background-color: ${colors.blue};
  }

  @media (${devices.s}) {
    > :not(:last-child) {
      border-bottom: 1px solid ${colors.darkGray}66;
    }
  }
`;
const ChevronDown = styled(IoChevronDown)`
  position: absolute;
  right: var(--option-padding-r);
  top: 50%;
  transform: translateY(-50%);
`;
export default OptionsDropdown;
