import React from 'react';
import styled from 'styled-components';
import {
  FullScreenModalContainer,
  ModalContentContainer,
  ModalTextBlock,
} from '../../styles/shared';
import ModalHeader from '../ModalHeader';
import { ownerModalContent } from '../../utils/dataConfig';
import OwnerForm from '../forms/OwnerForm';

const Owner = ({ handleClose }) => {
  return (
    <FullScreenModalContainer onClick={e => e.stopPropagation()}>
      <ModalHeader handleClose={handleClose} />
      <OwnerModalContent>
        <ModalTextBlock>
          <h1>{ownerModalContent.heading}</h1>
        </ModalTextBlock>
        <OwnerForm />
      </OwnerModalContent>
    </FullScreenModalContainer>
  );
};
const OwnerModalContent = styled(ModalContentContainer)`
  row-gap: 1.5rem;
`;
export default Owner;
