import React from 'react';
import styled from 'styled-components';
import { colors, devices, fontWeights } from '../styles/theme';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeBathroomColor,
  changeColor,
  changeOption,
  getSelectedIntent,
} from '../store/build';
import {
  formatExtraPerMonth,
  getAdjustedPriceFromIntent,
} from '../utils/helpers';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

const OptionTextures = ({
  selectedOption,
  selectedColor,
  colors,
  currentArea,
  currentBathroom,
}) => {
  const data = useStaticQuery(graphql`
    query getTextureImages {
      allFile(filter: { name: { regex: "/^texture/" } }) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(quality: 70, width: 144, placeholder: BLURRED)
          }
        }
      }
    }
  `);
  const selectedIntent = useSelector(getSelectedIntent);
  const dispatch = useDispatch();
  const handleClick = color => {
    switch (currentArea) {
      case 'bathroom':
        dispatch(changeBathroomColor(currentBathroom, color));
        break;
      case 'exterior-combined': {
        const [option, newColor] = color.split('+');
        dispatch(changeOption('exterior', option));
        dispatch(changeColor('exterior', newColor));
        break;
      }
      default:
        dispatch(changeColor(currentArea, color));
    }
  };
  const isExtraBathtubModule = currentBathroom?.startsWith('extra-bathtub');

  return (
    <Container>
      <Title>Color combinations</Title>
      <TextureOptions>
        {colors.list.map(colorName => {
          let newArea = null;
          let newOption = null;
          let newColor = null;
          if (currentArea === 'exterior-combined') {
            newArea = 'exterior';
            const [option, color] = colorName.split('+');
            newOption = option;
            newColor = color;
          }
          return (
            <TextureContainer
              key={colorName}
              onClick={() => handleClick(colorName)}
            >
              <GatsbyImage
                alt={`exterior ${
                  newOption || selectedOption
                } ${selectedColor} texture`}
                image={
                  data.allFile.nodes.find(
                    ({ name }) =>
                      name ===
                      `texture-${newArea || currentArea}${
                        selectedOption ? `-${newOption || selectedOption}` : ''
                      }-${newColor || colorName}`
                  ).childImageSharp.gatsbyImageData
                }
                objectFit='contain'
              />
              <div
                className='indicator'
                aria-current={(newColor || colorName) === selectedColor}
              />
            </TextureContainer>
          );
        })}
      </TextureOptions>
      {currentArea === 'exterior-combined' ? (
        <ColorSubtitle>
          {`${
            colors[`${selectedOption}+${selectedColor}`].title
          }  ${formatExtraPerMonth(
            getAdjustedPriceFromIntent(
              colors[`${selectedOption}+${selectedColor}`].price,
              selectedIntent
            )
          )}`}
        </ColorSubtitle>
      ) : (
        <ColorSubtitle>{`${colors[selectedColor].title}  ${
          colors[selectedColor].subtitle ||
          formatExtraPerMonth(
            isExtraBathtubModule
              ? 0
              : getAdjustedPriceFromIntent(
                  colors[selectedColor].price,
                  selectedIntent
                )
          )
        }`}</ColorSubtitle>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-gap: 0.875rem;
  @media (${devices.s}), (${devices.sv}) {
    grid-gap: 0.4rem;
  }
`;
const Title = styled.h3`
  font-size: 1.25rem;
  font-weight: ${fontWeights.bold};
  line-height: 2rem;
  border-bottom: 1px solid ${colors.black};
  width: 12.5rem;
  @media (${devices.s}) {
    font-size: 1rem;
    width: auto;
    max-width: 12.5rem;
  }
  padding-bottom: 0.5rem;
  margin-bottom: 0.625rem;
`;
const TextureOptions = styled.ul`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(4, var(--texture-option-container-size));
`;
const TextureContainer = styled.li`
  width: var(--texture-option-container-size);
  //height: var(--texture-option-container-size);
  display: grid;
  row-gap: 0.5rem;
  &:hover {
    .indicator {
      background-color: ${colors.dark};
    }
  }

  .indicator {
    height: 0.25rem;
    border-radius: 0.375rem;

    &[aria-current='true'] {
      background-color: ${colors.blue};
    }
  }
  cursor: pointer;
`;
const Color = styled.div`
  width: var(--color-option-size);
  height: var(--color-option-size);
  border-radius: 50%;
  --colors: ${({ color }) => `${color[0]}, ${color[1]}`};
  background: linear-gradient(var(--colors));
`;
const ColorSubtitle = styled.p`
  font-weight: ${fontWeights.regular};
  font-size: 1rem;
  line-height: 2rem;
`;
export default OptionTextures;
