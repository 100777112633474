import React from 'react';
import { useSelector } from 'react-redux';
import {
  getSelectedInterior,
  getSelectedInteriorAddOn,
  getSelectedUnit,
} from '../../store/build';
import { unitDataConfig } from '../../utils/dataConfig';
import {
  BuilderOptionsContainer,
  OptionsDescription,
  OptionsNote,
  OptionsTitle,
} from '../../styles/shared';
import styled from 'styled-components';
import OptionsCheckbox from '../OptionsCheckbox';
import { formatExtraPerMonthCheckbox } from '../../utils/helpers';
import OptionsDropdown from '../OptionsDropdown';
import { fontWeights } from '../../styles/theme';
import OptionTextures from '../OptionTextures';

const text = {
  title: 'Select interior',
  subtitle: `1. Start by selecting a style
2. Check if you would like it furnished`,
  note: '* Your configured price will be displayed below',
};

const InteriorOptions = () => {
  const selectedUnit = useSelector(getSelectedUnit);
  const selectedInterior = useSelector(getSelectedInterior);
  const selectedAddOn = useSelector(getSelectedInteriorAddOn);
  const interiorData = unitDataConfig.options[selectedUnit].interior;
  return (
    <Container>
      {selectedInterior && (
        <>
          <BuilderOptionsContainer>
            <OptionsTitle>Select interior</OptionsTitle>
            <div>
              <OptionsDescription>{text.subtitle}</OptionsDescription>
              <OptionsNote>{text.note}</OptionsNote>
            </div>
            <OptionTextures
              currentArea='interior'
              selectedColor={selectedInterior}
              colors={interiorData}
            />
            <Includes>
              <h3>Includes</h3>
              {interiorData.includes.map(item => (
                <li key={item}>{item}</li>
              ))}
            </Includes>
          </BuilderOptionsContainer>
          <OptionsCheckbox
            title='Fully furnished'
            subtitle={
              interiorData[selectedInterior].addOn.subtitle ||
              formatExtraPerMonthCheckbox(
                interiorData[selectedInterior].addOn.price
              )
            }
            isChecked={selectedAddOn}
            currentArea='interior'
            includes={interiorData.addOnIncludes}
          />
        </>
      )}
    </Container>
  );
};
const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
`;
const Includes = styled.ul`
  h3 {
    font-weight: ${fontWeights.medium};
    font-size: 1.25rem;
    line-height: 2rem;
    text-decoration: underline;
  }
  li {
    font-size: 1rem;
    line-height: 2rem;
  }
`;
export default InteriorOptions;
