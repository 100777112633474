import React, { useRef } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useSelector } from 'react-redux';
import {
  getBathroomConfig,
  getSelectedExteriorColor,
  getSelectedExteriorMaterial,
  getSelectedInterior,
  getSelectedRoof,
  getSelectedUnit,
} from '../../store/build';
import {
  getTrimColorByColorName,
  mapUnitToExteriorObjectPosition,
} from '../../utils/helpers';
import MediaCarousel from './MediaCarousel';

const SummaryMedia = () => {
  const data = useStaticQuery(graphql`
    query getSummaryImages {
      options: allFile(filter: { relativePath: { regex: "/^builder/" } }) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(quality: 70, height: 1080, placeholder: BLURRED)
          }
        }
      }
      communities: allFile(
        filter: { relativePath: { regex: "/^communities/" } }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(quality: 70, height: 1080, placeholder: BLURRED)
          }
        }
      }
    }
  `);

  const unit = useSelector(getSelectedUnit);
  const unitImage = data.options.nodes.find(
    ({ name }) => name === `unit-${unit}-1`
  );

  const exteriorMaterial = useSelector(getSelectedExteriorMaterial);
  const exteriorColor = useSelector(getSelectedExteriorColor);
  const exteriorImage = data.options.nodes.find(
    ({ name }) =>
      name === `exterior-${unit}-${exteriorMaterial}-${exteriorColor}`
  );

  const roof = useSelector(getSelectedRoof);
  const trimColor = getTrimColorByColorName(exteriorColor);
  const roofImageName = `roof-${unit}-${roof}${
    // garden roof does not have trim variants
    roof === 'garden' ? '' : `-${trimColor}`
  }`;
  const placeholderImage =
    roof === 'standard'
      ? null // standard roof has no additional roof image
      : data.options.nodes.find(({ name }) => name === 'roof-placeholder');
  const roofImage =
    data.options.nodes.find(({ name }) => name === roofImageName) ||
    placeholderImage;

  const interior = useSelector(getSelectedInterior);
  const interiorImages = data.options.nodes
    .filter(({ name }) => name.startsWith(`interior-${unit}-${interior}`))
    .sort((a, b) => (a.name > b.name ? 1 : -1));

  const bathroomConfig = useSelector(getBathroomConfig);
  const bathroomImages = [];
  bathroomConfig.list.forEach(bathroom => {
    const { option, color } = bathroomConfig[bathroom];
    const bathroomImage = data.options.nodes.find(
      ({ name }) => name === `bathroom-${option}-${color}`
    );
    bathroomImages.push(bathroomImage);
  });

  const images = [
    {
      images: [exteriorImage, roofImage],
      area: 'roof',
      objectPosition: mapUnitToExteriorObjectPosition[unit],
    },
    ...interiorImages.map(image => ({ image, area: 'interior' })),
    ...bathroomImages.map(image => ({ image, area: 'bathroom' })),
    { image: unitImage, area: 'unit' },
  ];

  const indexRef = useRef(0);
  return <MediaCarousel images={images} indexRef={indexRef} />;
};

export default SummaryMedia;
