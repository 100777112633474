import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useSelector } from 'react-redux';
import {
  getSelectedExteriorColor,
  getSelectedExteriorMaterial,
  getSelectedUnit,
} from '../../store/build';
import { BuilderMediaContainer } from '../../styles/shared';
import MediaImage from '../MediaImage';
import { mapUnitToExteriorObjectPosition } from '../../utils/helpers';

const ExteriorMedia = () => {
  const data = useStaticQuery(graphql`
    query getExteriorImages {
      allFile(filter: { name: { regex: "/^exterior/" } }) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(quality: 70, height: 1080, placeholder: BLURRED)
          }
        }
      }
    }
  `);
  const selectedUnit = useSelector(getSelectedUnit);
  const selectedMaterial = useSelector(getSelectedExteriorMaterial);
  const selectedColor = useSelector(getSelectedExteriorColor);
  // force unmount image when option changes
  const [currentImage, setImage] = useState(null);
  useEffect(() => {
    const image = data.allFile.nodes.find(
      ({ name }) =>
        name === `exterior-${selectedUnit}-${selectedMaterial}-${selectedColor}`
    );
    setImage(image);
    // setImage(null);
    // setTimeout(() => setImage(image));
  }, [selectedMaterial, selectedColor]);

  return (
    <BuilderMediaContainer>
      {currentImage && (
        <MediaImage
          image={currentImage}
          alt={`${selectedMaterial} ${selectedColor} exterior`}
          objectPosition={mapUnitToExteriorObjectPosition[selectedUnit]}
        />
      )}
    </BuilderMediaContainer>
  );
};
export default ExteriorMedia;
