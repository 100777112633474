import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { BuilderMediaContainer } from '../../styles/shared';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';
import ModuleFloorPlanSpace from './ModuleFloorPlanSpace';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeArea,
  changeAreaLimit,
  changeOption,
  getAreaLimit,
  getFloorPlan,
  getSelectedCommunity,
  getSelectedLot,
  getSelectedUnit,
  getTotalPrice,
  resetFloorPlan,
} from '../../store/build';
import ModuleOptionItem from './ModuleOptionItem';
import { colors, devices, fontWeights } from '../../styles/theme';
import { communityLotDataConfig, unitDataConfig } from '../../utils/dataConfig';
import { formatPricePerMonth, getPadding } from '../../utils/helpers';
import { MdScreenRotation } from '@react-icons/all-files/md/MdScreenRotation';
import { MdDevices } from '@react-icons/all-files/md/MdDevices';

const ModuleFloorPlan = () => {
  const data = useStaticQuery(graphql`
    query getBackgroundImage {
      background: file(name: { eq: "unit-background" }) {
        childImageSharp {
          gatsbyImageData(quality: 1, height: 400, placeholder: NONE)
        }
      }
    }
  `);
  const dispatch = useDispatch();
  const floorPlanArray = useSelector(getFloorPlan);
  const selectedUnit = useSelector(getSelectedUnit);
  const totalPrice = useSelector(getTotalPrice);

  // find bigger lots in the same community
  const selectedCommunity = useSelector(getSelectedCommunity);
  const selectedLot = useSelector(getSelectedLot);
  const communityData = communityLotDataConfig[selectedCommunity];
  const selectedLotLimit = communityData.lots[selectedLot].limit;
  const [biggerLots, setBiggerLots] = useState([]);
  useEffect(() => {
    const newBiggerLots = [];
    communityData.lots.list.forEach(lot => {
      const lotLimit = communityData.lots[lot].limit;
      if (
        lotLimit[0] > selectedLotLimit[0] &&
        lotLimit[1] > selectedLotLimit[1]
      ) {
        newBiggerLots.push({ name: lot, limit: lotLimit });
      }
    });
    setBiggerLots(newBiggerLots);
  }, [selectedLot]);

  return (
    <BuilderMediaContainer
      style={{
        display: 'grid',
        alignContent: 'center',
        padding: '1rem',
        position: 'relative',
      }}
    >
      <>
        <GatsbyImage
          alt='floor plan background'
          image={data.background.childImageSharp.gatsbyImageData}
          style={{
            height: '100%',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            // gridArea: '1 / 1 / -1 / -1',
            filter: 'opacity(20%)',
            zIndex: -1,
          }}
        />
        <ScreenSizeNotice>
          <MdScreenRotation size='60px' className='rotate' />
          <h2 className='rotate'>
            Please rotate your device to landscape mode
          </h2>
          <MdDevices size='60px' className='switch' />
          <h2 className='switch'>
            Please switch to a bigger device to customize your floor plan
          </h2>
          <MdDevices size='60px' className='resize' />
          <h2 className='resize'>
            Please widen the browser window to customize your floor plan
          </h2>
        </ScreenSizeNotice>
        {floorPlanArray && (
          <>
            <FloorPlanContainer
              padding={getPadding(
                floorPlanArray[0].length,
                floorPlanArray.length
              )}
            >
              <FloorPlan x={floorPlanArray[0].length} y={floorPlanArray.length}>
                {floorPlanArray.map((row, y) =>
                  row.map((item, x) => {
                    if (!item.vacant) {
                      if (item.spaceX) {
                        return (
                          <ModuleOptionItem
                            key={x + '-' + y}
                            item={item}
                            x={x}
                            y={y}
                            style={{
                              gridColumn: 'span ' + item.spaceX,
                              gridRow: 'span ' + item.spaceY,
                              width: '100%',
                            }}
                          />
                        );
                      }
                      // occupied array items
                      if (item.origin) {
                        return null;
                      }
                      return (
                        <ModuleOptionItem
                          key={x + '-' + y}
                          item={item}
                          x={x}
                          y={y}
                        />
                      );
                    } else {
                      return (
                        <ModuleFloorPlanSpace key={x + '-' + y} x={x} y={y} />
                      );
                    }
                  })
                )}
              </FloorPlan>
              {/*decor only*/}
              <FloorPlanDecor
                x={floorPlanArray[0].length}
                y={floorPlanArray.length}
              >
                {Array(floorPlanArray.length * floorPlanArray[0].length)
                  .fill(true)
                  .map((_, index) => (
                    <FloorPlanDecorSpace key={index} />
                  ))}
              </FloorPlanDecor>
            </FloorPlanContainer>
            <Footer>
              <p>
                {unitDataConfig.options[selectedUnit].title} + mods
                <span>{formatPricePerMonth(totalPrice)}</span>
              </p>
              <button type='button' onClick={() => dispatch(resetFloorPlan())}>
                reset to default
              </button>
            </Footer>
            {biggerLots.length > 0 && (
              <BiggerLots>
                <p>
                  your current lot size allows a maximum of{' '}
                  {selectedLotLimit[0] * selectedLotLimit[1]} modules. If you
                  would like to build a larger home, please choose from one of
                  these larger lot locations:
                </p>
                {biggerLots.map(lot => (
                  <button
                    key={lot.name}
                    onClick={() => {
                      dispatch(changeArea('community-lot'));
                      dispatch(changeOption('lot', lot.name));
                    }}
                  >
                    {
                      communityLotDataConfig[selectedCommunity].lots[lot.name]
                        .title
                    }{' '}
                    lot (fits {lot.limit[0] * lot.limit[1]} modules)
                  </button>
                ))}
              </BiggerLots>
            )}
          </>
        )}
      </>
    </BuilderMediaContainer>
  );
};
const FloorPlanContainer = styled.div`
  padding: ${props => props.padding};
  display: grid;
  grid-area: 1 / 1;
  justify-self: center;
  align-self: center;
  width: 100%;
  max-height: 100%;
  //background-color: ${colors.white};
  z-index: 1;
  @media (${devices.xs}) {
    display: none;
  }
`;
const ScreenSizeNotice = styled.div`
  display: none;
  @media (${devices.xs}) {
    display: grid;
  }
  grid-area: 1 / 1;
  justify-items: center;
  align-items: center;
  align-content: center;
  padding: 10vw;
  row-gap: 2rem;

  > * {
    line-height: 2rem;

    &.rotate {
      display: none;
      @media (min-height: 601px) and (orientation: portrait) and (hover: none) {
        display: block;
      }
    }

    &.switch {
      display: none;
      @media (max-height: 600px) and (hover: none) {
        display: block;
      }
    }

    &.resize {
      display: none;
      @media (hover: hover) and (pointer: fine) {
        display: block;
      }
    }
  }
`;
const FloorPlan = styled.div`
  grid-area: 1 / 1;
  align-self: center;
  display: grid;
  grid-template-columns: repeat(${props => props.x}, 1fr);
  grid-template-rows: repeat(${props => props.y}, 1fr);
  z-index: 2;

  // TODO? fill in padding to show maximum lot size
  width: 100%;
  height: 100%;
`;
const Footer = styled.div`
  padding: 1rem 0.5rem;
  display: flex;
  justify-content: space-between;

  > p {
    font-size: 1rem;
    line-height: 1.5rem;

    > span {
      margin-left: 1.5rem;
      font-weight: ${fontWeights.medium};
    }
  }

  > button {
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: ${colors.blue};
    text-decoration: underline;
  }
`;
const BiggerLots = styled.div`
  padding: 1rem 0.5rem;
  display: grid;
  row-gap: var(--radio-gap);
  justify-items: end;
  font-size: 0.875rem;
  line-height: 1.25rem;

  p {
    max-width: 30rem;
  }

  button {
    color: ${colors.blue};
    font-size: 0.875rem;

    :hover {
      text-decoration: underline;
    }
  }
`;
const FloorPlanDecor = styled(FloorPlan)`
  z-index: unset;

  --border-color: ${colors.blue}26;
  border: 1px solid var(--border-color);

  > * {
    border-right: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);
    position: relative;

    // blue circles at grid line crossings
    ::after {
      content: '';
      position: absolute;
      bottom: -2px;
      right: -2px;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: ${colors.blue};
      z-index: 1;
    }
  }

  // selecting last column
  > :nth-child(${props => props.x}n) {
    // unset grid lines
    border-right: unset;

    :last-child {
      border-bottom: unset;
    }

    // unset blue circles
    ::after {
      content: unset;
    }
  }

  // selecting last row
  > :nth-last-child(-n + ${props => props.x}) {
    // unset grid lines
    border-bottom: unset;

    :last-child {
      border-right: unset;
    }

    // unset circles
    ::after {
      content: unset;
    }
  }
`;
const FloorPlanDecorSpace = styled.div`
  ::before {
    // keep aspect ratio
    content: '';
    display: inline-block;
    width: 1px;
    height: 0;
    padding-bottom: 162%;
  }
`;
export default ModuleFloorPlan;
