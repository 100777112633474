import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { getSelectedRoof, getSelectedUnit } from '../../store/build';
import { unitDataConfig } from '../../utils/dataConfig';
import {
  BuilderOptionsContainer,
  OptionInfoDescription,
  OptionInfoTitle,
  OptionsDescription,
  OptionsNote,
  OptionsTitle,
} from '../../styles/shared';
import OptionsDropdown from '../OptionsDropdown';
const text = {
  title: 'Select roof',
  subtitle: `1. Start by selecting a roof style`,
  note: '* Your configured price will be displayed below',
};
const RoofOptions = () => {
  const selectedUnit = useSelector(getSelectedUnit);
  const selectedRoof = useSelector(getSelectedRoof);
  const roofData = unitDataConfig.options[selectedUnit].roof;

  return (
    <BuilderOptionsContainer>
      {roofData && (
        <>
          <OptionsTitle>{text.title}</OptionsTitle>
          <div>
            <OptionsDescription>{text.subtitle}</OptionsDescription>
            <OptionsNote>{text.note}</OptionsNote>
          </div>
          <OptionsDropdown
            currentArea='roof'
            options={roofData}
            selectedOption={selectedRoof}
          />
          {/*<InfoContainer>*/}
          {/*  <OptionInfoTitle>{roofData[selectedRoof].title}</OptionInfoTitle>*/}
          {/*  <OptionInfoDescription>*/}
          {/*    {roofData[selectedRoof].description}*/}
          {/*  </OptionInfoDescription>*/}
          {/*</InfoContainer>*/}
        </>
      )}
    </BuilderOptionsContainer>
  );
};

const InfoContainer = styled.article``;
export default RoofOptions;
