import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { colors, devices, fontWeights } from '../styles/theme';
import { IoChevronDown } from '@react-icons/all-files/io5/IoChevronDown';
import { useDispatch } from 'react-redux';
import Modal from './Modal';

const ListDropdown = ({ items, currentItem, action }) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const anchorEl = useRef(null);
  const [anchorStyle, setAnchorStyle] = useState(null);
  const handleClick = option => {
    open && dispatch(action(option));
    const { left, top, width } = anchorEl.current?.getBoundingClientRect();
    setAnchorStyle({ left, top, width });
    setOpen(prev => !prev);
  };
  return (
    <>
      <Container isHidden={open} ref={anchorEl} onClick={handleClick}>
        <p>{items[currentItem].title}</p>
        {items[currentItem].list &&
          (items[currentItem].list.length > 1 ? (
            <p className='items'>{items[currentItem].list.length} items</p>
          ) : (
            <p className='items'>{items[currentItem].list.length} item</p>
          ))}
        <IoChevronDown size='1.25rem' />
      </Container>
      {open && (
        <Modal anchorStyle={anchorStyle} handleClose={() => setOpen(false)}>
          <List>
            {[
              // Re-order dropdown so selected option is on top
              currentItem,
              ...items.list.filter(item => item !== currentItem),
            ].map(option => (
              <Item key={option} onClick={() => handleClick(option)}>
                <p>{items[option].title}</p>
              </Item>
            ))}
          </List>
        </Modal>
      )}
    </>
  );
};
const Container = styled.button`
  width: 100%;
  height: var(--list-dropdown-height);
  border: 1px solid ${colors.gray};
  &:hover {
    border-color: ${colors.black};
  }
  border-radius: 0.375rem;
  padding: var(--list-dropdown-padding);
  display: grid;
  grid-template-columns: 1fr auto auto;
  column-gap: var(--list-dropdown-col-gap);
  align-items: center;
  justify-items: start;
  visibility: ${props => (props.isHidden ? 'hidden' : 'visible')};
  background-color: ${colors.white};
  text-align: start;
  > p.items {
    @media (${devices.s}) {
      display: none;
    }
  }
`;
const List = styled.ul`
  border: 1px solid ${colors.black};
  border-radius: 0.375rem;
  background-color: ${colors.white};

  > *:hover {
    color: ${colors.white};
    background-color: ${colors.blue};
  }

  //box-shadow: 0 7px 10px -1px rgba(0, 0, 0, 0.43);
`;
const Item = styled(Container)`
  border: none;
  visibility: visible;
  display: flex;
  grid-template-columns: unset;
  column-gap: unset;
  justify-content: flex-start;
  p {
    font-size: 1rem;
    font-weight: ${fontWeights.regular};
  }
`;
export default ListDropdown;
