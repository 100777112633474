import React from 'react';
import styled from 'styled-components';
import {
  changeArea,
  changeItemGroup,
  getCurrentItemGroup,
} from '../../store/build';
import ListDropdown from '../ListDropdown';
import { moduleDataConfig } from '../../utils/dataConfig';
import { colors } from '../../styles/theme';
import { useDispatch, useSelector } from 'react-redux';
import ModuleOptionItem from './ModuleOptionItem';

const ModuleOptions = () => {
  const currentItemGroup = useSelector(getCurrentItemGroup);
  const dispatch = useDispatch();
  return (
    <Container>
      <CloseButton onClick={() => dispatch(changeArea('unit'))}>
        ← close panel
      </CloseButton>
      <ListDropdown
        items={moduleDataConfig}
        currentItem={currentItemGroup}
        action={changeItemGroup}
      />
      <Items>
        {moduleDataConfig[currentItemGroup].list.map(item => {
          // remove premium bathroom
          if (currentItemGroup === 'bathroom' && item === 'premium') {
            return null;
          }
          // multi module set padding and grid spans
          let paddingLeft = 0,
            paddingRight = 0,
            paddingBottom = 0,
            cols = moduleDataConfig[currentItemGroup][item].spaceX,
            rows = moduleDataConfig[currentItemGroup][item].spaceY;

          if (cols) {
            paddingLeft = paddingRight = `calc((${
              cols - 1
            } * var(--module-option-items-col-gap)) / 2)`;
            paddingBottom = `calc(${
              rows - 1
            } * var(--module-option-items-row-gap))`;
          }
          return (
            <ModuleOptionItem
              key={currentItemGroup + item}
              item={{ name: item, group: currentItemGroup }}
              style={{
                gridColumn: 'span ' + cols,
                gridRow: 'span ' + rows,
                paddingLeft,
                paddingRight,
                paddingBottom,
              }}
            />
          );
        })}
      </Items>
    </Container>
  );
};
const Rectangle = styled.div`
  width: 30%;
  position: relative;
  background-color: red;
  overflow: hidden;
  display: grid;

  ::before {
    grid-area: 1 / 1;
    content: '';
    display: inline-block;
    width: 1px;
    height: 0;
    padding-bottom: 165%;
  }
`;

const Container = styled.div`
  background-color: ${colors.f8};
  height: 100%;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 1rem;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${colors.darkGray};
    border-radius: 0.375rem;
  }

  ::-webkit-scrollbar-track {
    background-color: ${colors.darkGray}40;
  }

  padding: var(--builder-module-options-padding);
  border-radius: 0.375rem 0 0 0;
  border: 1px solid ${colors.gray};
  border-bottom: none;
  display: grid;
  align-content: start;
`;
const CloseButton = styled.button`
  color: ${colors.blue};
  font-size: 0.875rem;
  line-height: 2rem;
  height: min-content;
  justify-self: start;
  margin-bottom: 0.375rem;
`;
const Items = styled.div`
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  --module-option-items-col-gap: 1rem;
  --module-option-items-row-gap: 0.875rem;
  column-gap: var(--module-option-items-col-gap);
  row-gap: var(--module-option-items-row-gap);
  grid-auto-flow: dense;
`;

export default ModuleOptions;
