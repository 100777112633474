import React from 'react';
import styled from 'styled-components';
import { BuilderOptionsContainer, OptionsTitle } from '../../styles/shared';
import SummaryTable from '../SummaryTable';

const SummaryOptions = () => {
  return (
    <Container>
      <OptionsTitle>Summary</OptionsTitle>
      <SummaryTable />
    </Container>
  );
};
const Container = styled(BuilderOptionsContainer)`
  min-height: 100%;
  grid-template-rows: auto 1fr;
`;

export default SummaryOptions;
