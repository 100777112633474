import React from 'react';
import styled from 'styled-components';
import { colors, devices } from '../styles/theme';
import { useDispatch } from 'react-redux';
import { IoIosCheckbox } from '@react-icons/all-files/io/IoIosCheckbox';
import { toggleAddOn } from '../store/build';
import { AiOutlineQuestionCircle } from '@react-icons/all-files/ai/AiOutlineQuestionCircle';
import { fadeIn } from '../styles/shared';
import Checkbox from './Checkbox';

const OptionsCheckbox = ({
  title,
  subtitle,
  isChecked,
  currentArea,
  includes,
}) => {
  const dispatch = useDispatch();
  const handleClick = () => dispatch(toggleAddOn(currentArea));
  return (
    <Container>
      <Checkbox handleClick={handleClick} isChecked={isChecked}>
        <span>{title}</span>
        <span>{subtitle}</span>
        {includes && (
          <QuestionMark>
            <div className='tooltip'>
              {includes.map(item => (
                <p key={item}>{item}</p>
              ))}
              <Arrow />
            </div>
            <AiOutlineQuestionCircle size='1.25rem' />
          </QuestionMark>
        )}
      </Checkbox>
    </Container>
  );
};
const Container = styled.div`
  padding: var(--builder-grid-options-padding-x);
  @media (${devices.s}) {
    margin-top: 1rem;
  }
`;
const AddOnContainer = styled.div`
  padding: var(--builder-grid-options-padding-x);
  display: grid;
  grid-auto-flow: column;
  column-gap: 1rem;
  align-items: center;
  justify-content: start;
  cursor: pointer;
  &:hover {
    div.unchecked {
      border-color: ${colors.dark};
    }
  }
  @media (${devices.s}) {
    margin-top: 1rem;
    column-gap: 0.5rem;
  }
`;

const Unchecked = styled.div`
  border-radius: 0.25rem;
  width: var(--checkbox-size);
  height: var(--checkbox-size);
  border: 1px solid ${colors.gray};
`;
const Description = styled.p`
  font-size: 1rem;
  line-height: 2rem;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  column-gap: 0.5rem;
`;
const QuestionMark = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  .tooltip {
    position: absolute;
    right: -5rem;
    bottom: 18px;
    margin-bottom: 1rem;
    width: 17.5rem;
    display: none;
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem;
    line-height: 1.5rem;
    border: 1px solid ${colors.black};
    border-radius: 0.375rem;
    padding: var(--tooltip-padding-y) var(--tooltip-padding-x);
    filter: drop-shadow(2px 4px 4px ${colors.black}29);
    background-color: ${colors.white};
    animation: 0.15s ${fadeIn} ease-out;
  }
  :hover {
    .tooltip {
      display: grid;
    }
  }
`;
const Arrow = styled.div`
  background-color: ${colors.black};
  width: 1.5rem;
  height: 1rem;
  position: absolute;
  --offset: calc(1.5rem / 2 - 1.25rem / 2);
  right: calc(5rem - var(--offset));
  bottom: -1rem;
  clip-path: polygon(0 0, 100% 0, 50% 100%);
  :after {
    content: '';
    position: absolute;
    left: 2px;
    top: 0;
    width: calc(100% - 4px);
    height: calc(100% - 1px);
    background-color: ${colors.white};
    clip-path: polygon(0 0, 100% 0, 50% 100%);
  }
`;
export default OptionsCheckbox;
