import React, { useState } from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import {
  ComparisonActionLink,
  ComparisonItem,
  ComparisonLeft,
  ComparisonRight,
  ModalContentContainer,
  ModalTextBlock,
  PlayButton,
  PlayButtonBackground,
  Video,
  VideoContainer,
  VideoPoster,
} from '../../styles/shared';
import { ownRentModalContent } from '../../utils/dataConfig';
import { GatsbyImage } from 'gatsby-plugin-image';
import { FiCheck } from '@react-icons/all-files/fi/FiCheck';
import { colors, devices } from '../../styles/theme';

const OwnRentContent = ({ handleClose }) => {
  const data = useStaticQuery(graphql`
    query getOwnRentModalImages {
      allFile(filter: { name: { regex: "/^own-rent/" } }) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(quality: 70, width: 568, placeholder: BLURRED)
          }
        }
      }
    }
  `);
  const [showVideo, setShowVideo] = useState(false);
  const videoPoster = data.allFile.nodes.find(
    image => image.name === 'own-rent-video-thumbnail'
  );

  return (
    <ModalContentContainer>
      <ModalTextBlock>
        <h1>{ownRentModalContent.textBlock1.heading}</h1>
        <h2>{ownRentModalContent.textBlock1.subheading}</h2>
        <p>{ownRentModalContent.textBlock1.paragraph}</p>
      </ModalTextBlock>
      {showVideo ? (
        <VideoContainer>
          <Video width='100%' autoPlay controls>
            <source src={ownRentModalContent.video.url} type='video/mp4' />
          </Video>
        </VideoContainer>
      ) : (
        <VideoPoster>
          <GatsbyImage
            image={videoPoster.childImageSharp.gatsbyImageData}
            alt='video poster'
            objectFit='contain'
          />
          <PlayButton size='4rem' onClick={() => setShowVideo(true)} />
          <PlayButtonBackground />
        </VideoPoster>
      )}
      <ModalTextBlock>
        <h1>{ownRentModalContent.textBlock2.heading}</h1>
        <p>{ownRentModalContent.textBlock2.paragraph}</p>
      </ModalTextBlock>
      <ComparisonContainer>
        <ComparisonLeft>
          <h2>{ownRentModalContent.comparison.left.heading}</h2>
          {ownRentModalContent.comparison.left.items.map((item, index) => (
            <ComparisonItem key={index}>
              <FiCheck color={colors.white} size='1.2rem' />
              <p>{item}</p>
            </ComparisonItem>
          ))}
          <ComparisonActionLink
            to='/builder/?src=ownVsRent&i=own'
            onClick={handleClose}
            className='left'
          >
            Start Building
          </ComparisonActionLink>
        </ComparisonLeft>
        <ComparisonRight>
          <h2>{ownRentModalContent.comparison.right.heading}</h2>
          {ownRentModalContent.comparison.right.items.map((item, index) => (
            <ComparisonItem key={index}>
              <FiCheck color={colors.black} size='1.2rem' />
              <p>{item}</p>
            </ComparisonItem>
          ))}
          <ComparisonActionLink
            to='/builder/?src=ownVsRent&i=rent'
            onClick={handleClose}
            className='right'
          >
            Start Building
          </ComparisonActionLink>
        </ComparisonRight>
      </ComparisonContainer>
    </ModalContentContainer>
  );
};

const ComparisonContainer = styled.article`
  display: grid;
  column-gap: 1.5rem;
  grid-template-columns: repeat(2, 1fr);
  @media (${devices.s}) {
    column-gap: 0;
  }
  @media (${devices.xs}) {
    grid-template-columns: auto;
    row-gap: 4%;
    padding: 0 13% 25%;
  }
`;

export default OwnRentContent;
