import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useSelector } from 'react-redux';
import { getSelectedCommunity, getSelectedLot } from '../../store/build';
import { BuilderMediaContainer } from '../../styles/shared';
import MediaImage from '../MediaImage';

const CommunityLotMedia = () => {
  const data = useStaticQuery(graphql`
    query getCommunityLotImages {
      allFile(filter: { name: { regex: "/^communities/" } }) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(quality: 70, height: 1080, placeholder: BLURRED)
          }
        }
      }
    }
  `);
  const selectedCommunity = useSelector(getSelectedCommunity);
  const selectedLot = useSelector(getSelectedLot);
  // force unmount image when option changes
  const [currentImage, setImage] = useState(null);
  useEffect(() => {
    const image = data.allFile.nodes.find(
      ({ name }) => name === `communities-${selectedCommunity}-${selectedLot}`
    );
    setImage(null);
    setTimeout(() => setImage(image));
  }, [selectedCommunity, selectedLot]);

  return (
    <BuilderMediaContainer>
      {currentImage && (
        <MediaImage
          image={currentImage}
          alt={`${selectedCommunity} ${selectedLot} lot`}
        />
      )}
    </BuilderMediaContainer>
  );
};

export default CommunityLotMedia;
