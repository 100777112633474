import React, { useEffect, useRef, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useSelector } from 'react-redux';
import { getSelectedInterior, getSelectedUnit } from '../../store/build';
import MediaCarousel from './MediaCarousel';

const InteriorMedia = () => {
  const data = useStaticQuery(graphql`
    query getInteriorImages {
      allFile(filter: { name: { regex: "/^interior/" } }) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(quality: 80, height: 1080, placeholder: BLURRED)
          }
        }
      }
    }
  `);
  const selectedInterior = useSelector(getSelectedInterior);
  const selectedUnit = useSelector(getSelectedUnit);

  const [currentImages, setImages] = useState([]);
  useEffect(() => {
    indexRef.current = 0;
    const interiorImages = data.allFile.nodes
      .filter(({ name }) =>
        name.startsWith(`interior-${selectedUnit}-${selectedInterior}`)
      )
      .sort((a, b) => (a.name > b.name ? 1 : -1));
    const images = interiorImages.map(image => ({ image }));
    setTimeout(() => setImages(images));
  }, [selectedInterior]);
  const indexRef = useRef(0);

  return <MediaCarousel images={currentImages} indexRef={indexRef} />;
};

export default InteriorMedia;
