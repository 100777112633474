import React, { useState } from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import Modal from './Modal';
import ModalImage from './modals/ModalImage';
import { RiFullscreenFill } from '@react-icons/all-files/ri/RiFullscreenFill';
import { colors } from '../styles/theme';

const MediaImage = ({ image, images, alt, objectPosition }) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <ZoomButton onClick={() => setModalOpen(true)}>
        {image ? (
          <GatsbyImage
            image={image.childImageSharp.gatsbyImageData}
            alt={alt}
            style={{ gridArea: '1 / 1' }}
            imgStyle={{ objectPosition: objectPosition || 'unset' }}
          />
        ) : (
          <>
            <GatsbyImage
              image={images[0].childImageSharp.gatsbyImageData}
              alt={alt}
              style={{ gridArea: '1 / 1', zIndex: 0 }}
              imgStyle={{ objectPosition: objectPosition || 'unset' }}
            />
            {images[1] && (
              <GatsbyImage
                image={images[1].childImageSharp.gatsbyImageData}
                alt={alt}
                style={{ gridArea: '1 / 1', zIndex: 1 }}
                imgStyle={{ objectPosition: objectPosition || 'unset' }}
              />
            )}
          </>
        )}
        <FullScreenIcon size='32px' color={colors.gray} />
      </ZoomButton>
      {modalOpen && (
        <Modal
          anchorStyle={{
            width: '100%',
            height: '100%',
          }}
          handleClose={() => setModalOpen(false)}
        >
          <ModalImage
            image={image}
            images={images}
            alt={`${alt} full screen`}
            handleClose={() => setModalOpen(false)}
          />
        </Modal>
      )}
    </>
  );
};

const ZoomButton = styled.button`
  height: 100%;
  width: 100%;
  cursor: zoom-in;
  display: grid;
  align-items: stretch;
  justify-items: stretch;
`;
const FullScreenIcon = styled(RiFullscreenFill)`
  cursor: pointer;
  grid-area: 1 / 1;
  justify-self: end;
  align-self: end;
  margin: 1.25rem;
  z-index: 1;
  filter: drop-shadow(0px 0px 3px #000000);
  :hover {
    filter: brightness(200%);
    transform: scale(1.1);
  }
`;
export default MediaImage;
