import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import { useSelector } from 'react-redux';
import {
  getSelectedExteriorColor,
  getSelectedExteriorMaterial,
  getSelectedRoof,
  getSelectedUnit,
} from '../../store/build';
import MediaImage from '../MediaImage';
import {
  getTrimColorByColorName,
  mapUnitToExteriorObjectPosition,
} from '../../utils/helpers';

const RoofMedia = () => {
  const data = useStaticQuery(graphql`
    query getRoofImages {
      roofs: allFile(filter: { name: { regex: "/^roof/" } }) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(quality: 70, height: 1080, placeholder: NONE)
          }
        }
      }
      exteriors: allFile(filter: { name: { regex: "/^exterior/" } }) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(quality: 70, height: 1080, placeholder: BLURRED)
          }
        }
      }
    }
  `);
  const selectedUnit = useSelector(getSelectedUnit);
  const selectedMaterial = useSelector(getSelectedExteriorMaterial);
  const selectedColor = useSelector(getSelectedExteriorColor);
  const selectedRoof = useSelector(getSelectedRoof);
  const [currentImages, setImages] = useState([]);

  const placeholderImage =
    selectedRoof === 'standard'
      ? null // standard roof has no additional roof image
      : data.roofs.nodes.find(({ name }) => name === 'roof-placeholder');

  useEffect(() => {
    // compose image with two layers:
    // - lower layer is the exterior image
    // - top layer is the alpha overlay or full image
    const exteriorImage = data.exteriors.nodes.find(
      ({ name }) =>
        name === `exterior-${selectedUnit}-${selectedMaterial}-${selectedColor}`
    );
    const trimColor = getTrimColorByColorName(selectedColor);
    const roofImageName = `roof-${selectedUnit}-${selectedRoof}${
      // garden roof does not have trim variants
      selectedRoof === 'garden' ? '' : `-${trimColor}`
    }`;
    const roofImage =
      data.roofs.nodes.find(({ name }) => name === roofImageName) ||
      placeholderImage;
    setImages([exteriorImage, roofImage]);
  }, [selectedRoof]);

  return (
    <Container>
      {currentImages.length && (
        <>
          <MediaImage
            images={currentImages}
            alt={selectedRoof + ' roof'}
            style={{ gridArea: '1 / 1' }}
            objectPosition={mapUnitToExteriorObjectPosition[selectedUnit]}
          />
        </>
      )}
    </Container>
  );
};

const Container = styled.aside`
  height: 100%;
  display: grid;
`;

export default RoofMedia;
