import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { IoMdCloseCircle } from '@react-icons/all-files/io/IoMdCloseCircle';
import { colors } from '../../styles/theme';

const apiKey = 'AIzaSyCjzzKR_fsoQPTRfivtSd6rseOSJgsOZOc';

const Map = ({ data, handleClose }) => {
  const handleClick = e => {
    e.stopPropagation();
    handleClose();
  };
  const { placeId, latLong, center, zoom } = data;
  // adjust zoom level to viewport size
  const [adjustedZoom, setZoom] = useState(null);
  const containerEl = useRef(null);
  useEffect(() => {
    if (zoom) {
      const width = containerEl.current.getBoundingClientRect().width;
      width < 400
        ? setZoom(zoom - 1)
        : width > 1152
        ? setZoom(zoom + 1)
        : setZoom(zoom);
    } else {
      setZoom(true);
    }
  }, []);
  return (
    <Container onClick={handleClick} ref={containerEl}>
      <CloseButton
        color={colors.white}
        size='2.5rem'
        alt='close button'
        onClick={handleClose}
      />
      {adjustedZoom && (
        <MapIframe
          loading='eager'
          allowFullScreen
          center='30.289489809274823, -97.7487903627671'
          src={`https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=${latLong}&center=${center}${
            zoom ? `&zoom=${adjustedZoom}` : ''
          }`}
        />
      )}
      <Loading>loading map...</Loading>
    </Container>
  );
};
const Container = styled.article`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  padding: 3vh 5vw 5vh;
  background-color: ${colors.darkTransparent};
  justify-content: stretch;
`;
const MapIframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: 0;
  grid-area: 2 / 1;
  z-index: 1;
`;
const Loading = styled.h1`
  color: ${colors.white};
  font-size: 3vw;
  width: 100%;
  height: 100%;
  grid-area: 2 / 1;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const CloseButton = styled(IoMdCloseCircle)`
  cursor: pointer;
  justify-self: end;
  align-self: end;
  margin: 0.5rem 1rem;
`;
export default Map;
