import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import Logo from '../Logo';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeArea,
  getAreaLimit,
  getCurrentArea,
  getNavOn,
} from '../../store/build';
import { TextNavButton } from '../../styles/shared';
import { builderNavList } from '../../utils/dataConfig';
import { colors, devices, fontWeights } from '../../styles/theme';
import { IoChevronDown } from '@react-icons/all-files/io5/IoChevronDown';
import Modal from '../Modal';

const Nav = () => {
  const currentArea = useSelector(getCurrentArea);
  const areaLimited = useSelector(getAreaLimit);
  const navIsOn = useSelector(getNavOn);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const anchorEl = useRef(null);
  const [anchorStyles, setAnchor] = useState(null);
  const handleClick = () => {
    if (!open) {
      const { left, bottom, width } = anchorEl.current?.getBoundingClientRect();
      setAnchor({ top: bottom, left, width });
    }
    setOpen(prev => !prev);
  };

  return (
    <>
      <DesktopContainer>
        <Logo />
        {navIsOn &&
          builderNavList.map(({ title, name, controlled }) => (
            <TextNavButton
              key={name}
              aria-current={currentArea.startsWith(name)}
              disabled={!!(controlled && areaLimited)}
              type='button'
              onClick={() => dispatch(changeArea(name))}
            >
              {title}
            </TextNavButton>
          ))}
      </DesktopContainer>
      <MobileContainer>
        <Logo />
        <CurrentAreaDropdownContainer onClick={handleClick} ref={anchorEl}>
          <CurrentAreaTitle>
            Home Builder -{' '}
            {
              builderNavList.find(area => currentArea.startsWith(area.name))
                .title
            }
          </CurrentAreaTitle>
          <IoChevronDown
            size='1rem'
            style={open ? { transform: 'rotateZ(180deg)' } : {}}
          />
        </CurrentAreaDropdownContainer>
      </MobileContainer>
      {open && (
        <Modal anchorStyle={anchorStyles} handleClose={() => setOpen(false)}>
          <DropdownContainer>
            {builderNavList.map(({ title, name, controlled }) => (
              <CurrentAreaTitle
                key={name}
                aria-current={currentArea === name}
                data-disabled={!!(controlled && areaLimited)}
                onClick={() =>
                  !controlled
                    ? dispatch(changeArea(name))
                    : !areaLimited && dispatch(changeArea(name))
                }
              >
                {title}
              </CurrentAreaTitle>
            ))}
          </DropdownContainer>
        </Modal>
      )}
    </>
  );
};
const DesktopContainer = styled.div`
  width: 100%;
  padding: var(--builder-nav-padding);
  display: grid;
  justify-items: stretch;
  grid-template-columns: 1fr repeat(7, 9vw) 1fr;
  column-gap: 0.55vw;
  @media (${devices.s}) {
    grid-template-columns: 1fr repeat(7, auto) 1fr;
    column-gap: 1.15vw;
  }
  @media (${devices.xs}) {
    display: none;
  }
`;
const MobileContainer = styled.div`
  display: none;
  z-index: 5;
  @media (${devices.xs}) {
    width: 100%;
    padding: var(--builder-nav-padding);
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 1.5rem;
  }
`;
const CurrentAreaDropdownContainer = styled.button`
  display: grid;
  grid-template-columns: 1fr auto auto 1fr;
  column-gap: 0.5rem;
  align-items: center;

  > :first-child {
    grid-column: 2 / span 1;
  }
`;
const CurrentAreaTitle = styled.h1`
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: ${fontWeights.medium};
`;
const DropdownContainer = styled.div`
  padding: 1rem;
  background-color: ${colors.white};
  box-shadow: 0 7px 10px -1px rgba(0, 0, 0, 0.43);
  display: grid;
  row-gap: 1rem;
  justify-items: stretch;

  > * {
    height: 2.5rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    :hover:enabled {
      color: ${colors.blue};
      cursor: pointer;
    }
    &[data-disabled='true'] {
      color: ${colors.darkGray};
    }
    &[aria-current='true'] {
      border-left: 0.25rem solid ${colors.blue};
      background-color: ${colors.gray};
    }
  }
`;
export default Nav;
