import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getSelectedExteriorColor,
  getSelectedExteriorMaterial,
  getSelectedUnit,
} from '../../store/build';
import { unitDataConfig } from '../../utils/dataConfig';
import {
  BuilderOptionsContainer,
  OptionsDescription,
  OptionsNote,
  OptionsTitle,
} from '../../styles/shared';
import OptionsDropdown from '../OptionsDropdown';
import OptionTextures from '../OptionTextures';

const text = {
  title: 'Select exterior',
  subtitle: `1. Start by selecting a material
2. Pick a color combination`,
  note: '* Your configured price will be displayed below',
};

const ExteriorOptions = () => {
  const selectedUnit = useSelector(getSelectedUnit);
  const selectedMaterial = useSelector(getSelectedExteriorMaterial);
  const selectedColor = useSelector(getSelectedExteriorColor);
  const exteriorData = unitDataConfig.options[selectedUnit].exterior;

  const [combinedColorData, setColorData] = useState(null);
  useEffect(() => {
    if (['m10a', 'm16a'].includes(selectedUnit)) {
      console.log({ exteriorData });
      const newColorData = { list: [] };
      exteriorData.list.forEach(material => {
        exteriorData[material].colors.list.forEach(color => {
          newColorData.list.push(`${material}+${color}`);
          newColorData[`${material}+${color}`] =
            exteriorData[material].colors[color];
        });
      });
      setColorData(newColorData);
      console.log(newColorData);
    } else {
      setColorData(null);
    }
  }, [selectedUnit]);

  return (
    <BuilderOptionsContainer>
      <OptionsTitle>{text.title}</OptionsTitle>
      {!combinedColorData && (
        <>
          <div>
            <OptionsDescription>{text.subtitle}</OptionsDescription>
            <OptionsNote>{text.note}</OptionsNote>
          </div>
          {selectedMaterial && (
            <OptionsDropdown
              currentArea='exterior'
              options={exteriorData}
              selectedOption={selectedMaterial}
            />
          )}
        </>
      )}
      {selectedColor && (
        <OptionTextures
          currentArea={combinedColorData ? 'exterior-combined' : 'exterior'}
          selectedOption={selectedMaterial}
          selectedColor={selectedColor}
          colors={combinedColorData || exteriorData[selectedMaterial].colors}
        />
      )}
    </BuilderOptionsContainer>
  );
};

export default ExteriorOptions;
