import React, { useState } from 'react';
import styled from 'styled-components';
import { colors, devices, fontWeights } from '../../styles/theme';
import Modal from '../Modal';
import OwnRent from '../modals/OwnRent';
import Partner from '../modals/Partner';
import Renter from '../modals/Renter';
import Owner from '../modals/Owner';
import { LinkStyleButton } from '../../styles/shared';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAreaLimit,
  getNavOn,
  getSelectedIntent,
  getTotalDueToday,
  resetBuilder,
} from '../../store/build';
import { formatPrice } from '../../utils/helpers';
import SaveBuild from '../modals/SaveBuild';
import { navigate } from 'gatsby';

const SummaryBanner = () => {
  const selectedIntent = useSelector(getSelectedIntent);
  const totalDueToday = useSelector(getTotalDueToday);
  const areaLimited = useSelector(getAreaLimit);
  const navIsOn = useSelector(getNavOn);
  const dispatch = useDispatch();

  const [modal, setModal] = useState(null);
  const handleClose = () => setModal(null);
  const resetBuild = () => {
    dispatch(resetBuilder());
    navigate('/builder/', { replace: true });
  };
  return (
    <>
      <Container>
        <DueTodayContainer>
          <DueTodayNote>Due Today</DueTodayNote>
          <DueTodayTotal>{formatPrice(totalDueToday)}</DueTodayTotal>
          <PaymentNote>Refundable Deposit to hold your reservation</PaymentNote>
        </DueTodayContainer>
        <PrimaryButton onClick={() => setModal(selectedIntent)}>
          Reserve as {selectedIntent === 'own' ? 'Owner' : 'Renter'}
        </PrimaryButton>
        {navIsOn ? (
          <SecondaryButton onClick={() => setModal('saveBuild')}>
            Save your Build
          </SecondaryButton>
        ) : (
          <SecondaryButton onClick={resetBuild}>Build another</SecondaryButton>
        )}
        <TextButtons>
          <LinkStyleButton onClick={() => setModal('ownRent')}>
            Learn about Neu ownership
          </LinkStyleButton>
          <LinkStyleButton onClick={() => setModal('partner')}>
            Partner with Neu
          </LinkStyleButton>
        </TextButtons>
      </Container>
      {modal === 'ownRent' && (
        <Modal
          anchorStyle={{ width: '100%', height: '100%' }}
          handleClose={handleClose}
        >
          <OwnRent handleClose={handleClose} />
        </Modal>
      )}
      {modal === 'partner' && (
        <Modal
          anchorStyle={{ width: '100%', height: '100%' }}
          handleClose={handleClose}
        >
          <Partner handleClose={handleClose} />
        </Modal>
      )}
      {modal === 'rent' && (
        <Modal
          anchorStyle={{ width: '100%', height: '100%' }}
          handleClose={handleClose}
        >
          <Renter handleClose={handleClose} />
        </Modal>
      )}
      {modal === 'own' && (
        <Modal
          anchorStyle={{ width: '100%', height: '100%' }}
          handleClose={handleClose}
        >
          <Owner handleClose={handleClose} />
        </Modal>
      )}
      {modal === 'saveBuild' && (
        <Modal
          anchorStyle={{ width: '100%', height: '100%' }}
          handleClose={handleClose}
        >
          <SaveBuild handleClose={handleClose} />
        </Modal>
      )}
    </>
  );
};

const Container = styled.section`
  padding: var(--builder-grid-banner-padding);
  display: grid;
  justify-items: stretch;
  //margin-bottom: 0.875rem;
`;
const Tabs = styled.ul`
  margin-bottom: 0.625rem;
  display: flex;

  > h1 {
    flex: 1 1 50%;
    font-weight: ${fontWeights.medium};
    font-size: 1.25rem;
    line-height: 2rem;
    cursor: pointer;
    color: ${colors.darkGray};
    --bottom-border-size: 0.25rem;
    border-bottom: var(--bottom-border-size) solid ${colors.gray};

    &:hover {
      color: ${colors.black};
      border-bottom: var(--bottom-border-size) solid ${colors.dark};
    }

    &[aria-current='true'] {
      color: ${colors.black};
      border-bottom: var(--bottom-border-size) solid ${colors.blue};
    }

    @media (${devices.s}) {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }

  > h1 + h1 {
    margin-left: 1rem;
  }
`;
const Description = styled.p`
  font-size: 1rem;
  line-height: 1.5rem;
`;
const TextButtons = styled.div`
  display: grid;
  grid-auto-flow: column;
  column-gap: 0.5rem;
  justify-content: space-between;
  margin-top: 0.25rem;
  @media (${devices.s}) {
    margin-top: 1rem;
    grid-auto-flow: row;
    justify-content: center;
    row-gap: 0.75rem;
    margin-bottom: 1.25rem;
  }
  @media (${devices.xs}) {
    grid-auto-flow: column;
    justify-content: space-between;
    > :first-child {
      text-align: start;
    }

    > :last-child {
      text-align: end;
    }
  }
`;
const DueTodayContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 5.5rem;
  row-gap: 0.5rem;
  align-items: center;
  margin-bottom: 0.8rem;
`;
const DueTodayNote = styled.p`
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: ${fontWeights.medium};
  font-style: italic;
  @media (${devices.s}) {
    font-size: 0.8rem;
    line-height: 1rem;
  }
`;
const DueTodayTotal = styled.p`
  justify-self: start;
  font-size: 1.3rem;
  @media (${devices.s}) {
    font-size: 1.125rem;
  }
`;
const PaymentNote = styled.p`
  grid-column: span 2;
  font-size: 0.875rem;
  color: ${colors.darkTransparent};
  @media (${devices.s}) {
    font-size: 0.75rem;
  }
`;
const PrimaryButton = styled.button`
  width: 100%;
  text-align: center;
  height: 3.5rem;
  border-radius: 0.375rem;
  background-color: ${colors.blue};
  color: ${colors.white};

  :hover {
    opacity: 0.85;
  }
`;
const SecondaryButton = styled(PrimaryButton)`
  margin-top: 1rem;
  background-color: unset;
  color: unset;
  border: 1px solid ${colors.black};

  :hover {
    opacity: unset;
    border-color: ${colors.blue};
    color: ${colors.blue};
  }
`;
export default SummaryBanner;
