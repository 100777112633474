import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { colors, devices, fontWeights } from '../../styles/theme';
import NavControls from './NavControls';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeIntent,
  getSelectedCommunity,
  getSelectedIntent,
  getSelectedLot,
  getSelectedUnit,
  getTotalPrice,
} from '../../store/build';
import { formatPrice } from '../../utils/helpers';
import { communityLotDataConfig, unitDataConfig } from '../../utils/dataConfig';
import Modal from '../Modal';
import SummaryTable from '../SummaryTable';
import SliderToggle from '../SliderToggle';

const QuickFacts = () => {
  const totalPrice = useSelector(getTotalPrice);
  const selectedCommunity = useSelector(getSelectedCommunity);
  const selectedLot = useSelector(getSelectedLot);
  const selectedUnit = useSelector(getSelectedUnit);
  const selectedIntent = useSelector(getSelectedIntent);
  const dispatch = useDispatch();

  // view details modal control
  const [modalOpen, setModalOpen] = useState(false);
  const [anchorStyle, setAnchorStyle] = useState(null);
  const anchorEl = useRef(null);

  const updateAnchorStyle = () => {
    const { left, width } = anchorEl.current?.getBoundingClientRect();
    setAnchorStyle({
      left,
      width,
      bottom: 'var(--builder-grid-banner-padding-bottom)',
    });
  };
  const handleClick = () => {
    !modalOpen && updateAnchorStyle();
    setModalOpen(prev => !prev);
  };

  return (
    <Container>
      <NavControls />
      <InfoContainer ref={anchorEl}>
        <LeftSide>
          <SelectedCommunity>
            {communityLotDataConfig[selectedCommunity].title}
          </SelectedCommunity>
          <Item>
            {communityLotDataConfig[selectedCommunity].lots[selectedLot].title}
          </Item>
          {selectedUnit && (
            <>
              <Item>{unitDataConfig.options[selectedUnit].title}</Item>
              <DetailsButton onClick={handleClick}>
                your configuration
              </DetailsButton>
            </>
          )}
        </LeftSide>
        <RightSide>
          <Price>{formatPrice(totalPrice)}</Price>
          <PriceNote className='note'>Estimated Monthly Total</PriceNote>
          <SliderToggle
            className='toggle'
            current={selectedIntent}
            left={{ title: 'Owner', value: 'own' }}
            right={{ title: 'Renter', value: 'rent' }}
            handleClick={() =>
              dispatch(changeIntent(selectedIntent === 'own' ? 'rent' : 'own'))
            }
          />
        </RightSide>
      </InfoContainer>
      {modalOpen && (
        <Modal anchorStyle={anchorStyle} handleClose={handleClick}>
          <DetailsModalContainer onClick={e => e.stopPropagation()}>
            <SummaryTable isModal />
            <DetailsButton onClick={handleClick}>hide details</DetailsButton>
          </DetailsModalContainer>
        </Modal>
      )}
    </Container>
  );
};
const Container = styled.div`
  padding: var(--builder-grid-banner-padding);
`;
const InfoContainer = styled.section`
  border-top: 1px solid ${colors.black};
  padding: 0.875rem 0 0;
  display: grid;
  align-content: start;
  grid-template-columns: 1fr auto;
  grid-template-rows: 1fr auto;
  column-gap: 0.5rem;

  @media (${devices.s}) {
    padding: 1rem 0 0;
  }
`;
const LeftSide = styled.div`
  justify-self: start;
  display: grid;
  grid-template-rows: auto auto auto 1fr auto;
  @media (${devices.s}) {
    row-gap: 0.2rem;
    grid-template-rows: repeat(4, auto);
  }
`;
const SelectedCommunity = styled.h2`
  font-size: 1rem;
  font-weight: ${fontWeights.bold};
  line-height: 1.5rem;
  @media (${devices.s}) {
    font-size: 1rem;
    line-height: 1.2rem;
  }
  @media (${devices.xs}) {
    font-size: 1rem;
  }
`;
const Item = styled.p`
  font-size: 1rem;
  font-weight: ${fontWeights.regular};
  line-height: 1.5rem;
  @media (${devices.s}) {
    line-height: 1.2rem;
  }
`;
const RightSide = styled.div`
  justify-self: end;
  display: grid;
  align-items: start;
  justify-items: start;
  align-content: start;
  row-gap: 0.5rem;

  .toggle {
    justify-self: end;
  }

  .note {
    margin-bottom: 1rem;
  }

  @media (${devices.s}) {
    justify-items: end;
  }
`;
const Price = styled.h1`
  font-size: 1.75rem;
  font-weight: ${fontWeights.bold};
  align-self: center;
  @media (${devices.s}) {
    font-size: 1.25rem;
  }
`;
const PriceNote = styled.p`
  font-size: 0.75rem;
  font-weight: ${fontWeights.regular};
  line-height: 1rem;
  text-align: end;
`;
const DetailsButton = styled.button`
  grid-row: span 1 / -1;
  text-decoration: underline;
  color: ${colors.blue};
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-weight: ${fontWeights.regular};
  justify-self: start;
  text-align: start;
  margin-top: 0.375rem;

  :hover {
    opacity: 0.8;
  }

  @media (${devices.s}) {
    line-height: 1.25rem;
    margin-top: unset;
  }
`;
const DetailsModalContainer = styled.section`
  background-color: ${colors.white};
`;
export default QuickFacts;
