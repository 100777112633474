import React from 'react';
import {
  FullScreenModalContainer,
  ModalContentContainer,
  ModalTextBlock,
} from '../../styles/shared';
import ModalHeader from '../ModalHeader';
import { renterModalContent } from '../../utils/dataConfig';
import RenterForm from '../forms/RenterForm';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { getVisitorInfo, resetFormSubmission } from '../../store/app';
import { toggleNav } from '../../store/build';

const Renter = ({ handleClose }) => {
  const { buildUrl } = useSelector(getVisitorInfo);
  const dispatch = useDispatch();

  const handleClick = () => {
    if (buildUrl) {
      dispatch(toggleNav(false));
      dispatch(resetFormSubmission('renter'));
      handleClose();
    } else {
      handleClose();
    }
  };
  return (
    <FullScreenModalContainer onClick={e => e.stopPropagation()}>
      <ModalHeader handleClose={handleClick} />
      <RenterModalContent>
        <ModalTextBlock>
          <h1>{renterModalContent.heading}</h1>
        </ModalTextBlock>
        <RenterForm />
      </RenterModalContent>
    </FullScreenModalContainer>
  );
};
const RenterModalContent = styled(ModalContentContainer)`
  row-gap: 1.5rem;
`;
export default Renter;
