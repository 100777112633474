import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeArea,
  changeAreaLimit,
  changeOption,
  getAreaLimit,
  getSelectedCommunity,
  getSelectedIntent,
  getSelectedLot,
  getSelectedUnit,
} from '../../store/build';
import { communityLotDataConfig, unitDataConfig } from '../../utils/dataConfig';
import {
  BuilderOptionsContainer,
  OptionsDescription,
  OptionsNote,
  OptionsTitle,
} from '../../styles/shared';
import OptionsDropdown from '../OptionsDropdown';
import { colors, devices } from '../../styles/theme';
import { BsExclamationCircle } from '@react-icons/all-files/bs/BsExclamationCircle';
import {
  formatPricePerMonth,
  getAdjustedPriceFromIntent,
} from '../../utils/helpers';

const getUnitSubtitle = (unit, community, lot, intent) => {
  const totalPrice = getAdjustedPriceFromIntent(
    unitDataConfig.options[unit].price +
      communityLotDataConfig[community].lots[lot].price,
    intent
  );
  return formatPricePerMonth(totalPrice);
};

const UnitOptions = () => {
  const dispatch = useDispatch();
  const selectedCommunity = useSelector(getSelectedCommunity);
  const selectedLot = useSelector(getSelectedLot);
  const lotUnitOptions =
    communityLotDataConfig[selectedCommunity].lots[selectedLot].units.list;
  const selectedUnit = useSelector(getSelectedUnit);
  const selectedIntent = useSelector(getSelectedIntent);

  // select default unit if none was selected
  useEffect(() => {
    !selectedUnit && dispatch(changeOption('unit', lotUnitOptions[0]));
  }, []);

  // compose data to include unavailable options in dropdown
  const selectedUnitData = unitDataConfig.options[selectedUnit] || null;
  // manually remove m16a from lake-travis
  const allUnitOptions =
    selectedCommunity === 'lake-travis'
      ? unitDataConfig.options.list.filter(unit => unit !== 'm16a')
      : unitDataConfig.options.list;
  const unitOptions = { list: allUnitOptions };
  lotUnitOptions.forEach(unit => {
    unitOptions[unit] = {
      ...unitDataConfig.options[unit],
      subtitle: getUnitSubtitle(
        unit,
        selectedCommunity,
        selectedLot,
        selectedIntent
      ),
    };
  });
  allUnitOptions.forEach(unit => {
    !unitOptions[unit] &&
      (unitOptions[unit] = {
        ...unitDataConfig.options[unit],
        subtitle: 'available in another lot',
      });
  });

  // update navigation limit
  const areaLimited = useSelector(getAreaLimit);

  // remove limit if it's a valid option
  useEffect(() => {
    lotUnitOptions.includes(selectedUnit) &&
      areaLimited === 'invalid-unit' &&
      dispatch(changeAreaLimit(false));
  }, [selectedLot, selectedUnit, selectedCommunity]);

  // find lots that include the current unit type
  const [recommendedLots, setLots] = useState([]);
  useEffect(() => {
    if (!lotUnitOptions.includes(selectedUnit)) {
      setLots([]);
      const availableLots = communityLotDataConfig[selectedCommunity].lots;
      const lotsWithCurrentUnit = [];
      availableLots.list.forEach(lot => {
        availableLots[lot].units.list.includes(selectedUnit) &&
          lotsWithCurrentUnit.push(lot);
      });
      setLots(lotsWithCurrentUnit);
    }
  }, [selectedUnit]);

  return (
    <BuilderOptionsContainer>
      {selectedUnitData && (
        <>
          <OptionsTitle>{unitDataConfig.title}</OptionsTitle>
          <div>
            <OptionsDescription>{unitDataConfig.subtitle}</OptionsDescription>
            <OptionsNote>{unitDataConfig.note}</OptionsNote>
          </div>
          <OptionsDropdown
            currentArea='unit'
            options={unitOptions}
            selectedOption={selectedUnit}
          />
          {areaLimited === 'invalid-unit' ? (
            recommendedLots.length > 0 && (
              <RecommendedLots>
                <BsExclamationCircle size='1.5rem' />
                <p>{unitOptions[selectedUnit].title} is only available with:</p>
                {recommendedLots.map(lot => (
                  <button
                    key={lot}
                    onClick={() => dispatch(changeOption('lot', lot))}
                  >
                    {communityLotDataConfig[selectedCommunity].lots[lot].title}
                  </button>
                ))}
              </RecommendedLots>
            )
          ) : (
            <>
              {/*<CustomizeButton*/}
              {/*  onClick={() => dispatch(changeArea('unit-module'))}*/}
              {/*>*/}
              {/*  Start Customizing*/}
              {/*</CustomizeButton>*/}
              {areaLimited === 'empty-floor-plan' && (
                <EmptyFloorPlanMessage>
                  Floor plan empty, please add at least one module on the floor
                  plan to continue
                </EmptyFloorPlanMessage>
              )}
            </>
          )}
        </>
      )}
    </BuilderOptionsContainer>
  );
};

const CustomizeButton = styled.button`
  justify-self: center;
  width: 12rem;
  height: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.375rem;
  border: 1px solid ${colors.gray};

  :hover {
    border-color: ${colors.dark};
  }
`;
const RecommendedLots = styled.div`
  display: grid;
  row-gap: 0.75rem;
  justify-items: start;
  border-radius: 0.375rem;
  background-color: ${colors.light};
  padding: 1.25rem 1rem;

  svg {
    margin-bottom: 0.5rem;
  }

  p {
    line-height: 1.5rem;
  }

  button {
    color: ${colors.blue};

    :hover {
      text-decoration: underline;
    }
  }

  @media (${devices.s}) {
    row-gap: 0.5rem;
  }
`;
const EmptyFloorPlanMessage = styled.p`
  color: ${colors.red};
  line-height: 1.5rem;
`;
export default UnitOptions;
