import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useSelector } from 'react-redux';
import { getBathroomConfig, getCurrentBathroom } from '../../store/build';
import { BuilderMediaContainer } from '../../styles/shared';
import MediaImage from '../MediaImage';

const BathroomMedia = () => {
  const data = useStaticQuery(graphql`
    query getBathroomImages {
      allFile(filter: { name: { regex: "/^bathroom/" } }) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(quality: 70, height: 1080, placeholder: BLURRED)
          }
        }
      }
    }
  `);
  const bathroomConfig = useSelector(getBathroomConfig);
  const currentBathroom = useSelector(getCurrentBathroom);
  const { option, color } = bathroomConfig[currentBathroom];
  // force unmount image when option changes
  const [currentImage, setImage] = useState(null);
  useEffect(() => {
    const image = data.allFile.nodes.find(
      ({ name }) => name === `bathroom-${option}-${color}`
    );
    setImage(image);
    // setImage(null);
    // setTimeout(() => setImage(image));
  }, [option, color]);
  return (
    <BuilderMediaContainer>
      {currentImage && (
        <MediaImage image={currentImage} alt={`${option} ${color} bathroom`} />
      )}
    </BuilderMediaContainer>
  );
};

export default BathroomMedia;
