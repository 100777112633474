import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import {
  changeCurrentBathroom,
  getBathroomConfig,
  getCurrentBathroom,
  getSelectedUnit,
} from '../../store/build';
import { moduleDataConfig, unitDataConfig } from '../../utils/dataConfig';
import { BuilderOptionsContainer, OptionsTitle } from '../../styles/shared';
import OptionsDropdown from '../OptionsDropdown';
import { devices, fontWeights } from '../../styles/theme';
import ListRadio from '../ListRadio';
import OptionTextures from '../OptionTextures';

const BathroomOptions = () => {
  const selectedUnit = useSelector(getSelectedUnit);
  const selectedUnitTemplateBathrooms =
    unitDataConfig.options[selectedUnit].bathroom;
  const bathroomConfigState = useSelector(getBathroomConfig);
  const floorPlanAddedBathroomList = bathroomConfigState.extraModuleList;
  // compose data for List Dropdown component
  const allBathrooms = {
    ...selectedUnitTemplateBathrooms,
  };
  floorPlanAddedBathroomList.length > 0 &&
    floorPlanAddedBathroomList.forEach(name => {
      allBathrooms.list = [...allBathrooms.list, name];
      const displayModuleName = name.split('-').slice(-1);
      allBathrooms[name] = {
        title: `Extra ${displayModuleName}`,
      };
    });
  const currentBathroom = useSelector(getCurrentBathroom);

  return (
    <BuilderOptionsContainer>
      <OptionsTitle>Design bathroom</OptionsTitle>
      {allBathrooms.list.length > 1 && (
        <ListRadio
          items={allBathrooms}
          currentItem={currentBathroom}
          action={changeCurrentBathroom}
        />
      )}
      <BathroomGroup>
        <div>
          <BathroomName>
            {allBathrooms[currentBathroom].title} bathroom type:
          </BathroomName>
          <OptionsDropdown
            currentArea='bathroom'
            currentBathroom={currentBathroom}
            options={moduleDataConfig.bathroom}
            selectedOption={bathroomConfigState[currentBathroom].option}
          />
        </div>
        <OptionTextures
          currentArea='bathroom'
          currentBathroom={currentBathroom}
          selectedOption={bathroomConfigState[currentBathroom].option}
          selectedColor={bathroomConfigState[currentBathroom].color}
          colors={
            moduleDataConfig.bathroom[
              bathroomConfigState[currentBathroom].option
            ].colors
          }
        />
      </BathroomGroup>
    </BuilderOptionsContainer>
  );
};
const BathroomName = styled.h3`
  font-size: 1.25rem;
  font-weight: ${fontWeights.bold};
  line-height: 2rem;
  @media (${devices.s}) {
    font-size: 1rem;
    width: auto;
    max-width: 12.5rem;
  }
  padding-bottom: 0.5rem;
`;
const BathroomGroup = styled.div`
  display: grid;
  row-gap: 1.25rem;
`;
export default BathroomOptions;
