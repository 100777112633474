import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getSubmittedForms, getVisitorInfo } from '../../store/app';
import {
  Form,
  FormActionButton,
  FormDueToday,
  FormFootNote,
  FormPricing,
  Input,
  InputGroup,
  ThankYou,
} from '../../styles/shared';
import { renterModalContent } from '../../utils/dataConfig';
import useForm from '../../utils/useForm';
import { formatPrice, validateForm } from '../../utils/helpers';
import { submitRenter } from '../../utils/api';
import { getTotalDownPayment, getTotalPrice } from '../../store/build';
import { Link } from 'gatsby';

const formName = 'renter';

const RenterForm = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const visitorInfo = useSelector(getVisitorInfo);
  const formSubmitted = useSelector(getSubmittedForms).some(
    f => f === formName
  );
  const totalPrice = useSelector(getTotalPrice);
  const downPaymentTotal = useSelector(getTotalDownPayment);

  const { values, handleChange, handleBlur, handleSubmit, errors } = useForm(
    formName,
    visitorInfo,
    validateForm,
    isSubmitting,
    submitting => setSubmitting(submitting),
    submitRenter
  );
  return (
    <>
      <Form onSubmit={handleSubmit}>
        {formSubmitted ? (
          <ThankYou>
            <h2>{renterModalContent.thankYouMessage}</h2>
            <div>
              <p>Use the link below to share your build:</p>
              <a href={visitorInfo.buildUrl}>{visitorInfo.buildUrl}</a>
            </div>
          </ThankYou>
        ) : (
          <>
            <InputGroup>
              <p>First name</p>
              <Input
                type='text'
                name='firstName'
                onChange={handleChange}
                value={values.firstName}
                onBlur={handleBlur}
                required
                className={!!errors.firstName ? 'error' : ''}
              />
              <p className='error'>{errors.firstName}</p>
            </InputGroup>
            <InputGroup>
              <p>Last name</p>
              <Input
                type='text'
                name='lastName'
                onChange={handleChange}
                value={values.lastName}
                onBlur={handleBlur}
                required
                className={!!errors.lastName ? 'error' : ''}
              />
              <p className='error'>{errors.lastName}</p>
            </InputGroup>
            <InputGroup>
              <p>Email address</p>
              <Input
                type='email'
                name='email'
                onChange={handleChange}
                value={values.email}
                onBlur={handleBlur}
                required
                className={!!errors.email ? 'error' : ''}
              />
              <p className='error'>{errors.email}</p>
            </InputGroup>
            <InputGroup>
              <p>Phone number</p>
              <Input
                type='tel'
                name='phone'
                onChange={handleChange}
                value={values.phone}
                onBlur={handleBlur}
                className={!!errors.phone ? 'error' : ''}
              />
              <p className='error'>{errors.phone}</p>
            </InputGroup>
            <FormPricing>
              <p className='note'>
                A Neu Team member will reach out to you
                to confirm your reservation. You will be able to edit or change
                your Neu Home before final confirmation.
              </p>
              <FormDueToday>
                <h2>DUE TODAY</h2>
                <h1>$0</h1>
              </FormDueToday>
            </FormPricing>
            <FormActionButton
              type='submit'
              disabled={isSubmitting || Object.keys(errors).length !== 0}
            >
              {isSubmitting ? 'Submitting...' : 'Place Reservation'}
            </FormActionButton>
            {errors.submission && (
              <p className='error' style={{ gridColumn: '1 / -1' }}>
                {errors.submission}
              </p>
            )}
          </>
        )}
      </Form>
      <FormFootNote>
        By clicking "Place Reservation" I agree to the{' '}
        <a href='/terms/' target='_blank'>
          Terms and Conditions
        </a>
        , the{' '}
        <a href='/privacy/' target='_blank'>
          Customer Privacy Agreement
        </a>
        , and consent to be contacted at the number provided with more
        information or offers about Neu products. I understand my consent to be
        contacted is not a condition of purchase.
      </FormFootNote>
    </>
  );
};

export default RenterForm;
