import React, { useState } from 'react';
import styled from 'styled-components';
import { colors, devices, fontWeights } from '../../styles/theme';
import { GatsbyImage } from 'gatsby-plugin-image';
import { MdOpenInNew } from '@react-icons/all-files/md/MdOpenInNew';
import { graphql, useStaticQuery } from 'gatsby';
import {
  communitiesPageContent,
  communityLotDataConfig,
} from '../../utils/dataConfig';
import Modal from '../Modal';
import ModalImage from '../modals/ModalImage';
import Map from '../modals/Map';

const mapCommunityToHero = { mountain: 0, 'lake-travis': 1, bastrop: 2 };
const mapCommunityToObjectPosition = {
  mountain: '50% 90%',
  'lake-travis': '50% 0%',
  bastrop: '50% 65%',
};
const CommunityInfo = ({ community, showMap }) => {
  const data = useStaticQuery(graphql`
    query getCommunityInfoImages {
      heroThumbnails: allFile(
        filter: { name: { regex: "/^home-community/" } }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(quality: 70, height: 144, placeholder: BLURRED)
          }
        }
      }
      heroes: allFile(filter: { name: { regex: "/^home-community/" } }) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(quality: 70, width: 1440, placeholder: BLURRED)
          }
        }
      }
      mapThumbnails: allFile(filter: { name: { regex: "/^community-map/" } }) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(quality: 70, height: 144, placeholder: BLURRED)
          }
        }
      }
      maps: allFile(filter: { name: { regex: "/^community-map/" } }) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(quality: 70, width: 1440, placeholder: BLURRED)
          }
        }
      }
    }
  `);
  const heroThumbnailImages = data.heroThumbnails.nodes;
  const heroImages = data.heroes.nodes.sort((a, b) =>
    a.name > b.name ? 1 : -1
  );
  const mapThumbnailImage = data.mapThumbnails.nodes.find(
    ({ name }) => name === `community-map-${community}`
  );
  const mapImage = data.maps.nodes.find(
    ({ name }) => name === `community-map-${community}`
  );
  const [modal, setModal] = useState(null);
  const handleClose = () => setModal(null);

  return (
    <Container>
      <h3>{communityLotDataConfig[community].title}</h3>
      <CommunityImage onClick={() => setModal('scenery')}>
        <GatsbyImage
          image={
            heroThumbnailImages.find(
              image => image.name === `home-community-${community}`
            ).childImageSharp.gatsbyImageData
          }
          alt={community + ' scenery'}
          style={{ height: '100%' }}
          objectPosition={mapCommunityToObjectPosition[community]}
        />
      </CommunityImage>
      {showMap && (
        <MapSection>
          <GatsbyImage
            image={mapThumbnailImage.childImageSharp.gatsbyImageData}
            alt={community + ' map'}
            style={{ gridArea: '1 / 1' }}
          />
          {/*<OpenButton onClick={() => setModal('map')}>*/}
          {/*  <MdOpenInNew size='1rem' />*/}
          {/*</OpenButton>*/}
        </MapSection>
      )}
      {modal === 'scenery' && (
        <Modal
          anchorStyle={{
            width: '100%',
            height: '100%',
          }}
          handleClose={handleClose}
        >
          <ModalImage
            image={heroImages.find(
              image => image.name === `home-community-${community}`
            )}
            alt={`${community} scenery full screen`}
            handleClose={handleClose}
          />
        </Modal>
      )}
      {modal === 'map' && (
        <Modal
          anchorStyle={{
            width: '100%',
            height: '100%',
          }}
          handleClose={handleClose}
        >
          <Map
            data={communitiesPageContent.community[community].map}
            handleClose={handleClose}
          />
        </Modal>
      )}
    </Container>
  );
};

const Container = styled.article`
  z-index: 4;
  position: absolute;
  top: min(6%, 3.5rem);
  left: min(6%, 3.5rem);
  width: min(30%, 12.5rem);
  padding: 0.5rem;
  background-color: ${colors.white};
  display: grid;
  grid-gap: 0.5rem;
  border-radius: 0.375rem;
  box-shadow: 0 2px 8px -1px rgba(0, 0, 0, 0.5);
  align-items: center;
  h3 {
    //text-align: center;
    font-size: 1rem;
    font-weight: ${fontWeights.medium};
    line-height: 1.25rem;
    margin-bottom: -0.4rem;
    @media (${devices.xs}) {
      margin-bottom: unset;
    }
  }
  & * {
    border-radius: 0.375rem;
  }
  --item-height: 4.5rem;
  @media (${devices.xs}) {
    top: 3%;
    left: 3%;
    --item-height: 3.5rem;
    grid-template-columns: min-content 6rem;
    grid-auto-flow: column;
    grid-auto-columns: 6rem;
    width: auto;
  }
`;
const CommunityImage = styled.div`
  height: var(--item-height);
  cursor: pointer;
`;
const MapSection = styled.div`
  display: grid;
  height: var(--item-height);
`;
const OpenButton = styled.button`
  grid-area: 1 / 1;
  align-self: end;
  justify-self: end;
  background-color: ${colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
  z-index: 6;
`;
export default CommunityInfo;
