import React from 'react';
import styled from 'styled-components';
import { BuilderOptionsContainer, OptionsTitle } from '../../styles/shared';
import OptionsDropdown from '../OptionsDropdown';
import { communityLotDataConfig, unitDataConfig } from '../../utils/dataConfig';
import { useSelector } from 'react-redux';
import {
  getSelectedCommunity,
  getSelectedIntent,
  getSelectedLot,
} from '../../store/build';
import { devices } from '../../styles/theme';
import { formatPrice, getAdjustedPriceFromIntent } from '../../utils/helpers';

const getLotSubtitle = (lotPrice, units, intent) => {
  const prices = [];
  units.forEach(unit =>
    prices.push(unitDataConfig.options[unit].price + lotPrice)
  );
  const minPrice = Math.min(...prices);
  const adjustedPrice = getAdjustedPriceFromIntent(minPrice, intent);
  return `starting at ${formatPrice(adjustedPrice)} / mon`;
};

const CommunityLotOptions = () => {
  const selectedCommunity = useSelector(getSelectedCommunity);
  const selectedLot = useSelector(getSelectedLot);
  const selectedIntent = useSelector(getSelectedIntent);

  const lotsData = {
    list: communityLotDataConfig[selectedCommunity].lots.list,
  };
  lotsData.list.forEach(lot => {
    const lotData = communityLotDataConfig[selectedCommunity].lots[lot];
    lotsData[lot] = {
      ...lotData,
      subtitle: getLotSubtitle(
        lotData.price,
        lotData.units.list,
        selectedIntent
      ),
    };
  });
  return (
    <BuilderOptionsContainer>
      <OptionGroup>
        <OptionsTitle>Select community</OptionsTitle>
        <OptionsDropdown
          currentArea='community'
          options={communityLotDataConfig}
          selectedOption={selectedCommunity}
        />
      </OptionGroup>
      <OptionGroup>
        <OptionsTitle>Select lot</OptionsTitle>
        <OptionsDropdown
          currentArea='lot'
          options={lotsData}
          selectedOption={selectedLot}
        />
      </OptionGroup>
    </BuilderOptionsContainer>
  );
};
const OptionGroup = styled.div`
  display: grid;
  row-gap: 0.75rem;
  @media (${devices.s}) {
    row-gap: 0.5rem;
  }
`;

export default CommunityLotOptions;
