import React from 'react';
import styled from 'styled-components';
import { useDrop } from 'react-dnd';
import { dragDropItemTypes } from '../../utils/dataConfig';
import { useDispatch, useSelector } from 'react-redux';
import {
  addItemToFloorPlan,
  getFloorPlan,
  moveItemOnFloorPlan,
} from '../../store/build';
import { colors } from '../../styles/theme';
import { canPlaceHere } from '../../utils/helpers';

const ModuleFloorPlanSpace = ({ x, y }) => {
  // drag and drop
  const floorPlanArray = useSelector(getFloorPlan);
  const dispatch = useDispatch();

  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: dragDropItemTypes.MODULE,
      canDrop: item => canPlaceHere(x, y, item, floorPlanArray),
      drop: item => handleDrop(x, y, item),
      collect: monitor => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [floorPlanArray]
  );
  const handleDrop = (x, y, { x: prevX, y: prevY, group, name }) => {
    typeof prevX === 'undefined'
      ? dispatch(addItemToFloorPlan(x, y, { group, name }))
      : dispatch(
          moveItemOnFloorPlan(
            {
              x: prevX,
              y: prevY,
            },
            { x, y }
          )
        );
  };
  return <Container ref={drop} isOver={isOver} canDrop={canDrop} />;
};
const Container = styled.div`
  background-color: ${({ isOver, canDrop }) =>
    canDrop && (isOver ? colors.lightTransparent : colors.offWhiteTransparent)};
`;
export default ModuleFloorPlanSpace;
