import React from 'react';
import styled from 'styled-components';
import { TransparentBackgroundModalContainer } from '../../styles/shared';
import { colors, devices, fontWeights } from '../../styles/theme';
import SaveBuildForm from '../forms/SaveBuildForm';
import closeIcon from '../../assets/icons/close.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSubmittedForms,
  getVisitorInfo,
  resetFormSubmission,
} from '../../store/app';
import { changeAreaLimit, toggleNav } from '../../store/build';

const formName = 'saveBuild';

const SaveBuild = ({ handleClose }) => {
  const formSubmitted = useSelector(getSubmittedForms).some(
    f => f === formName
  );
  const { buildUrl } = useSelector(getVisitorInfo);
  const dispatch = useDispatch();
  const handleClick = () => {
    if (buildUrl) {
      dispatch(toggleNav(false));
      dispatch(resetFormSubmission(formName));
      handleClose();
    } else {
      handleClose();
    }
  };
  return (
    <TransparentBackgroundModalContainer onClick={handleClick}>
      <DialogContainer onClick={e => e.stopPropagation()}>
        {formSubmitted ? (
          <>
            <div>
              <h1>Thanks for saving your build</h1>
              <p>Share your build using the link below:</p>
            </div>
            <a href={buildUrl}>{buildUrl}</a>
          </>
        ) : (
          <>
            <div>
              <h1>Enter email to save this build</h1>
              <p>We will email you a link to your configuration</p>
            </div>
            <SaveBuildForm formName={formName} />
          </>
        )}
        <CloseButton src={closeIcon} alt='close button' onClick={handleClick} />
      </DialogContainer>
    </TransparentBackgroundModalContainer>
  );
};
const DialogContainer = styled.article`
  padding: 4.5rem 2rem 3rem;
  margin: 1rem;
  display: grid;
  position: relative;
  row-gap: 2rem;
  border-radius: 0.375rem;
  background-color: ${colors.white};
  h1 {
    font-weight: ${fontWeights.bold};
    font-size: 2rem;
    line-height: 3rem;
  }
  p {
    font-size: 1.125rem;
  }
  a {
    color: ${colors.blue};
    :hover {
      text-decoration: underline;
    }
  }
  @media (${devices.xs}) {
    h1 {
      font-size: 1.5rem;
      line-height: 2rem;
    }
  }
`;
const CloseButton = styled.img`
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
`;
export default SaveBuild;
