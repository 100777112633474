import React from 'react';
import styled from 'styled-components';
import { colors, devices } from '../../styles/theme';
import { RiDragDropLine } from '@react-icons/all-files/ri/RiDragDropLine';
import { changeArea } from '../../store/build';
import { useDispatch } from 'react-redux';

const ModuleBanner = () => {
  const dispatch = useDispatch();
  return (
    <Container>
      <Note>
        <RiDragDropLine size='40' />
        <NoteParagraph>Drag and drop modules onto the grid.</NoteParagraph>
      </Note>
      <ActionButton onClick={() => dispatch(changeArea('unit'))}>
        Finish<span> Customizing</span>
      </ActionButton>
    </Container>
  );
};
const Container = styled.div`
  padding: var(--builder-module-banner-padding);
  background-color: ${colors.f8};
  border-radius: 0 0 0 0.375rem;
  border: 1px solid ${colors.gray};
  border-top: none;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
`;
const Note = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  column-gap: 1rem;
  width: min-content;
`;
const NoteParagraph = styled.p`
  width: 7rem;
  font-size: 0.875rem;
  line-height: 1.5rem;
`;
const ActionButton = styled.button`
  width: max-content;
  min-width: 80%;
  justify-self: end;
  height: 3.5rem;
  border-radius: 0.375rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.blue};
  color: ${colors.white};
  padding: 0 1.25rem;
  white-space: pre;
  @media (${devices.s}) {
    & span {
      display: none;
    }
  }
`;
export default ModuleBanner;
