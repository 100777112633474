import React from 'react';
import { FullScreenModalContainer } from '../../styles/shared';
import ModalHeader from '../ModalHeader';
import OwnRentContent from './OwnRentContent';

const OwnRent = ({ handleClose }) => {
  return (
    <FullScreenModalContainer onClick={e => e.stopPropagation()}>
      <ModalHeader handleClose={handleClose} />
      <OwnRentContent handleClose={handleClose} />
    </FullScreenModalContainer>
  );
};

export default OwnRent;
