import React, { useState } from 'react';
import {
  MediaCarouselContainer,
  ModalCarousel,
  ModalCarouselWrapper,
  ModalCloseButton,
} from '../../styles/shared';
import ImageCarousel from '../ImageCarousel';
import Modal from '../Modal';
import { colors } from '../../styles/theme';

const MediaCarousel = ({ images, indexRef }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const handleImageClick = e =>
    (e.target.tagName === 'IMG' || e.target.id === 'full-screen-icon') &&
    setModalOpen(true);

  const handleModalClick = e => {
    e.stopPropagation();
    e.target.id === 'modal-background' && setModalOpen(false);
  };
  return (
    <>
      <MediaCarouselContainer onClick={handleImageClick}>
        {images.length && (
          <ImageCarousel
            images={images}
            reportIndex={index => (indexRef.current = index)}
            fullScreenIcon
          />
        )}
      </MediaCarouselContainer>
      {modalOpen && (
        <Modal
          anchorStyle={{
            width: '100%',
            height: '100%',
          }}
          handleClose={() => setModalOpen(false)}
        >
          <ModalCarouselWrapper
            id='modal-background'
            onClick={handleModalClick}
          >
            <ModalCloseButton
              color={colors.white}
              size='2.5rem'
              alt='close button'
              onClick={() => setModalOpen(false)}
            />
            <ModalCarousel
              images={images}
              initialIndex={indexRef.current}
              objectFit='contain'
            />
          </ModalCarouselWrapper>
        </Modal>
      )}
    </>
  );
};

export default MediaCarousel;
