import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { dragDropItemTypes, moduleDataConfig } from '../../utils/dataConfig';
import { useDrag, useDrop } from 'react-dnd';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { formatPricePerMonth } from '../../utils/helpers';
import { IoMdCloseCircle } from '@react-icons/all-files/io/IoMdCloseCircle';
import { colors, devices } from '../../styles/theme';
import { useDispatch } from 'react-redux';
import {
  removeItemFromFloorPlan,
  resetWalls,
  updateWall,
} from '../../store/build';
import { MdRestore } from '@react-icons/all-files/md/MdRestore';
import Modal from '../Modal';

const cornerPairs = [
  ['top', 'left'],
  ['top', 'right'],
  ['bottom', 'left'],
  ['bottom', 'right'],
];

const ModuleOptionItem = ({ item, x, y, style }) => {
  const data = useStaticQuery(graphql`
    query getModuleImages {
      allFile(filter: { name: { regex: "/^module/" } }) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              quality: 60
              height: 500
              placeholder: BLURRED
              blurredOptions: { width: 10 }
            )
          }
        }
      }
    }
  `);
  const getImage = (moduleGroup, optionName) => {
    return (
      data.allFile.nodes.find(
        ({ name }) => name === `module-${moduleGroup}-${optionName}`
      )?.childImageSharp.gatsbyImageData ||
      data.allFile.nodes.find(({ name }) => name === `module-placeholder`)
        .childImageSharp.gatsbyImageData
    );
  };

  const { group, name, fromTemplate, moduleName, walls, spaceX, spaceY } = item;
  const onFloorPlan = !(typeof x === 'undefined');

  // template module deletion prompt modal
  const [modal, setModal] = useState(null);
  const [remove, setRemove] = useState(false);
  const handleRemoveModule = () => {
    // show prompt if from template
    fromTemplate
      ? setModal('confirmDelete')
      : dispatch(removeItemFromFloorPlan(x, y));
  };
  const handleConfirmRemove = () => {
    setRemove(true);
    setModal(null);
  };
  useEffect(() => {
    if (remove) {
      dispatch(removeItemFromFloorPlan(x, y));
      setRemove(false);
    }
  }, [remove]);

  const hasWalls = walls && Object.values(walls).length > 0;

  // Corners
  const [corners, setCorners] = useState([]);
  // useEffect(() => {
  //   const newCorners = [];
  //   cornerPairs.forEach(pair => {
  //     // two adjacent walls
  //     if (walls && walls[pair[0]] && walls[pair[1]]) {
  //       // multi module space suffix
  //       const spaces = spaceX ? `-${spaceX}-${spaceY}` : '';
  //       newCorners.push(`corner-${pair[0]}-${pair[1]}${spaces}`);
  //     }
  //   });
  //   setCorners(newCorners);
  // }, [walls]);

  const dispatch = useDispatch();

  // drag as a module to or on floor plan
  const [{ isDraggingModule }, dragModule, dragModulePreview] = useDrag(
    () => ({
      type: dragDropItemTypes.MODULE,
      item: {
        group,
        name,
        fromTemplate,
        x,
        y,
      },
      collect: monitor => ({
        isDraggingModule: !!monitor.isDragging(),
      }),
      // previewOptions: { captureDraggingState: true },
    }),
    [x, y, group, name]
  );

  // drag as a wall to floor plan
  const [{ isDraggingVerticalWall }, dragWallVertical] = useDrag(() => ({
    type: dragDropItemTypes.WALL_VERTICAL,
    item: { name },
    previewOptions: { captureDraggingState: false, anchorX: 0, anchorY: 0 },
    collect: monitor => ({
      isDraggingVerticalWall: !!monitor.isDragging(),
    }),
  }));
  const [{ isDraggingHorizontalWall }, dragWallHorizontal] = useDrag(() => ({
    type: dragDropItemTypes.WALL_HORIZONTAL,
    item: { name },
    collect: monitor => ({
      isDraggingHorizontalWall: !!monitor.isDragging(),
    }),
  }));

  // drop zone for walls
  const [{ isOverLeft }, dropLeft] = useDrop(
    () => ({
      accept: dragDropItemTypes.WALL_VERTICAL,
      // canDrop: item => canDropHere(x, y, item, floorPlanArray),
      drop: wall => handleWallDrop(x, y, 'left', wall),
      collect: monitor => ({
        isOverLeft: monitor.isOver(),
        // canDrop: monitor.canDrop(),
      }),
    }),
    [x, y]
  );
  const [{ isOverRight }, dropRight] = useDrop(
    () => ({
      accept: dragDropItemTypes.WALL_VERTICAL,
      // canDrop: item => canDropHere(x, y, item, floorPlanArray),
      drop: wall => handleWallDrop(x, y, 'right', wall),
      collect: monitor => ({
        isOverRight: monitor.isOver(),
        // canDrop: monitor.canDrop(),
      }),
    }),
    [x, y]
  );
  const [{ isOverTop }, dropTop] = useDrop(
    () => ({
      accept: dragDropItemTypes.WALL_HORIZONTAL,
      // canDrop: item => canDropHere(x, y, item, floorPlanArray),
      drop: wall => handleWallDrop(x, y, 'top', wall),
      collect: monitor => ({
        isOverTop: monitor.isOver(),
        // canDrop: monitor.canDrop(),
      }),
    }),
    [x, y]
  );
  const [{ isOverBottom }, dropBottom] = useDrop(
    () => ({
      accept: dragDropItemTypes.WALL_HORIZONTAL,
      // canDrop: item => canDropHere(x, y, item, floorPlanArray),
      drop: wall => handleWallDrop(x, y, 'bottom', wall),
      collect: monitor => ({
        isOverBottom: monitor.isOver(),
        // canDrop: monitor.canDrop(),
      }),
    }),
    [x, y]
  );
  const handleWallDrop = (destinationX, destinationY, side, wall) => {
    dispatch(updateWall(destinationX, destinationY, side, wall));
  };

  return group !== 'wall' ? (
    <ModuleContainer
      ref={dragModule}
      className={isDraggingModule && 'grabbing'}
      style={style}
    >
      <GatsbyImage
        image={getImage(group, name)}
        alt={`${group} ${name} module`}
        style={{ gridArea: '1 / 1', width: '100%' }}
        objectFit='contain'
      />
      {/*dummy drag preview element*/}
      <div
        ref={dragModulePreview}
        style={{ gridArea: '1 / 1', width: '100%' }}
      />
      {hasWalls &&
        Object.keys(walls).map(side => {
          const wallType = walls[side].split('-').slice(1);
          // multi module wall suffix
          const spaces = spaceX ? `-${spaceX}-${spaceY}` : '';
          const wallName = `${wallType}-${side}${spaces}`;
          return (
            <GatsbyImage
              key={wallName}
              image={getImage('wall', wallName)}
              alt={`${name} wall`}
              style={{ gridArea: '1 / 1', width: '100%' }}
            />
          );
        })}
      {corners.length > 0 &&
        corners.map(cornerName => (
          <GatsbyImage
            key={cornerName}
            image={getImage('wall', cornerName)}
            alt='corner'
            style={{ gridArea: '1 / 1', width: '100%' }}
          />
        ))}
      {!onFloorPlan && (
        <Price>
          {formatPricePerMonth(moduleDataConfig[group][name]?.price || 0)}
        </Price>
      )}
      <Actions className='actions'>
        {onFloorPlan && (
          <IoMdCloseCircle size='1.75rem' onClick={handleRemoveModule} />
        )}
        {hasWalls && (
          <MdRestore
            size='1.75rem'
            onClick={() => dispatch(resetWalls(x, y))}
          />
        )}
      </Actions>
      {onFloorPlan && !fromTemplate && group === 'bathroom' && (
        <ModuleName>extra {moduleName.split('-').slice(-1)} </ModuleName>
      )}
      {onFloorPlan && (
        <>
          <DropZones skew={moduleDataConfig[group][name].skew}>
            <Bottom ref={dropBottom} className={isOverBottom && 'is-over'} />
            <Top ref={dropTop} className={isOverTop && 'is-over'} />
            <Left ref={dropLeft} className={isOverLeft && 'is-over'} />
            <Right ref={dropRight} className={isOverRight && 'is-over'} />
          </DropZones>
        </>
      )}
      {modal === 'confirmDelete' && (
        <Modal
          handleClose={() => setModal(null)}
          anchorStyle={{ width: '100%', height: '100%' }}
        >
          <DeletePromptContainer>
            <DeletePrompt onClick={e => e.stopPropagation()}>
              <h1>Remove template module?</h1>
              <p>
                This module is from the current unit template, deleting it does
                not reduce the starting price. To restore this module you will
                need to reset the floor plan. Would you still like to remove the
                module?
              </p>
              <div className='actions'>
                <button className='confirm' onClick={handleConfirmRemove}>
                  Remove
                </button>
                <button className='cancel' onClick={() => setModal(null)}>
                  Cancel
                </button>
              </div>
            </DeletePrompt>
          </DeletePromptContainer>
        </Modal>
      )}
    </ModuleContainer>
  ) : (
    <WallContainer
      ref={name.startsWith('vertical-') ? dragWallVertical : dragWallHorizontal}
      className={
        (isDraggingVerticalWall || isDraggingHorizontalWall) && 'grabbing'
      }
    >
      <GatsbyImage
        image={getImage(group, name)}
        alt={`${group} ${name} module`}
        style={{ gridArea: '1 / 1', width: '100%' }}
      />
      <Price>{moduleDataConfig.wall[name].title}</Price>
    </WallContainer>
  );
};
const ModuleContainer = styled.div`
  cursor: grab;

  &.grabbing {
    cursor: grabbing;
    opacity: 0.25;
  }

  width: 100%;
  display: grid;
  justify-items: center;
  position: relative;

  //::before {
  //  // keep aspect ratio
  //  grid-area: 1 / 1;
  //  content: '';
  //  display: inline-block;
  //  width: 1px;
  //  height: 0;
  //  padding-bottom: 165%;
  //}

  &:hover {
    p,
    .actions {
      display: grid;
    }
  }
`;
const WallContainer = styled(ModuleContainer)`
  background-color: ${colors.white};
`;
const Price = styled.p`
  font-size: 0.875rem;
  line-height: 1.75rem;
  padding-top: 4px;
  text-align: center;
  @media (${devices.s}) {
    line-height: 1.25rem;
  }
`;
const Actions = styled.div`
  display: none;
  row-gap: 0.3rem;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;

  > * {
    cursor: pointer;
    color: ${colors.blue};
    background-color: ${colors.white}CC;
    border-radius: 50%;
  }
`;
const ModuleName = styled.p`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  color: ${colors.white};
  background-color: ${colors.darkTransparent};
  z-index: 1;
  display: none;
  padding: 0.3rem 0.2rem 0.2rem;
`;
// separate the rectangle into 4 areas
// https://stackoverflow.com/a/30679639/9087670
const DropZones = styled.div`
  grid-area: 1/1;
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  //background-color: cyan;
  --skew-degree: ${({ skew }) => skew || '18deg'};
`;
const Bottom = styled.div`
  position: absolute;
  height: calc(100% / 1.72 * 1.65 * 1.65);
  width: calc(100% / 1.72 * 1.65 * 1.65);
  top: 50%;
  left: 50%;
  //background-color: greenyellow;
  transform-origin: 0 0;

  --skew-bottom-top: var(--skew-degree), var(--skew-degree);
  --skew-left-right: calc(0deg - var(--skew-degree)),
    calc(0deg - var(--skew-degree));
  transform: rotate(45deg) skew(var(--skew-bottom-top));

  &.is-over {
    background-color: ${colors.blue}99;
  }
`;
const Top = styled(Bottom)`
  //background-color: orangered;
  transform: rotate(-135deg) skew(var(--skew-bottom-top));
`;
const Left = styled(Bottom)`
  //background-color: mediumpurple;
  transform: rotate(135deg) skew(var(--skew-left-right));
`;
const Right = styled(Bottom)`
  //background-color: darkblue;
  transform: rotate(-46deg) skew(var(--skew-left-right));
`;

const DeletePromptContainer = styled.section`
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.darkTransparent};
`;
const DeletePrompt = styled.div`
  width: 80%;
  max-width: 500px;
  padding: var(--modal-prompt-padding);
  background-color: ${colors.white};
  display: grid;
  row-gap: 2rem;

  h1 {
  }

  p {
    line-height: 1.5rem;
  }

  div.actions {
    display: grid;
    column-gap: var(--modal-prompt-padding-x);
    grid-auto-flow: column;
    justify-content: end;

    button {
      justify-self: center;
      padding: 0 2rem;
      border: 1px solid ${colors.gray};
      border-radius: 0.375rem;
      max-width: var(--button-width);
      height: var(--button-height);

      &.confirm {
        background-color: ${colors.blue};
        color: ${colors.white};
      }

      &.cancel {
      }
    }
  }
`;
export default ModuleOptionItem;
