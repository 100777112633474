import React from 'react';
import styled from 'styled-components';
import { colors } from '../styles/theme';

const SliderToggle = ({ className, current, left, right, handleClick }) => {
  return (
    <Container onClick={handleClick} className={className}>
      <Text aria-current={current === left.value}>{left.title}</Text>
      <Text aria-current={current === right.value}>{right.title}</Text>
      <input type='checkbox' />
      <Background
        id='background'
        className={current === left.value ? 'left' : 'right'}
      />
    </Container>
  );
};
const Container = styled.div`
  input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }

  position: relative;
  width: 10rem;
  height: 2.5rem;
  --radius: 1.25rem;
  border-radius: var(--radius);
  border: 1px solid ${colors.gray};
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-items: center;
  cursor: pointer;
  :hover {
    div#background {
      opacity: 0.85;
    }
  }
  > div#background {
    transition: left 0.2s ease;
  }
`;
const Background = styled.div`
  background-color: ${colors.blue};
  width: 50%;
  height: 100%;
  border-radius: var(--radius);
  position: absolute;
  top: 0;

  &.left {
    left: 0;
  }

  &.right {
    left: 50%;
  }
`;
const Text = styled.label`
  font-size: 0.85rem;
  cursor: inherit;
  z-index: 1;

  &[aria-current='true'] {
    color: ${colors.white};
  }
`;
export default SliderToggle;
