import React from 'react';
import styled from 'styled-components';
import { TransparentBackgroundModalContainer } from '../../styles/shared';
import { colors, devices, fontWeights } from '../../styles/theme';
import closeIcon from '../../assets/icons/close.svg';
import { useDispatch, useSelector } from 'react-redux';
import { changeAreaLimit, toggleNav } from '../../store/build';

const BuilderDisclaimer = ({ handleClose }) => {
  const dispatch = useDispatch();
  const handleClick = () => {
    handleClose();
  };
  return (
    <TransparentBackgroundModalContainer onClick={handleClick}>
      <DialogContainer onClick={e => e.stopPropagation()}>
        <div>
          <h1>Get ready to build your Neu home!</h1>
          <h3>Just a few things to cover before you get started:</h3>
          <ol>
            <li>Use the online builder to build your Neu dream home. Explore different combinations and have fun!</li>
            <li>Each Neu Community has limited availability. To reserve your spot, complete your home build, then pay the <strong>$100 fully refundable deposit</strong>.</li>
            <li>Once your reservation is confirmed, we will get you scheduled with a Neu Showroom visit. At the showroom, you will be able to modify and finetune your customized Neu home, receive final pricing, and review the terms of your purchase.</li>
          </ol>
          <p>Have fun building, and remember, the choices you make today can always be changed when you get to the showroom. So let your imagination run wild and start building your future!</p>
          <p>Welcome to your Neu Community.</p>
          <button type='button' onClick={handleClick}>
            Got it!
          </button>
        </div>
        <CloseButton src={closeIcon} alt='close button' onClick={handleClick} />
      </DialogContainer>
    </TransparentBackgroundModalContainer>
  );
};
const DialogContainer = styled.article`
  max-width: 50vw;
  padding: 3.5rem 1.5rem 1.5rem;
  margin: 1rem;
  display: grid;
  position: relative;
  row-gap: 2rem;
  border-radius: 0.375rem;
  line-height: 2rem;
  background-color: ${colors.white};
  h1 {
    font-weight: ${fontWeights.bold};
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  p {
    margin: 1rem 0;
  }
  button {
    margin: 0 auto;
    display: block;
    padding: 0 2rem;
    border: 1px solid ${colors.gray};
    border-radius: 0.375rem;
    max-width: var(--button-width);
    height: var(--button-height);
    background-color: ${colors.blue};
    color: ${colors.white};
    &:hover {
      opacity: 0.85;
    }
  }
  a {
    color: ${colors.blue};
    :hover {
      text-decoration: underline;
    }
  }
  @media (${devices.s}) {
    max-width: 75vw;
  }
  @media (${devices.xs}) {
    padding: 3.25rem 1.5rem 1.5rem;
    line-height: 1.5rem;
    max-width: 95vw;
    h1 {
      font-size: 1.25rem;
    }
    h3 {
      font-size: 1rem;
    }
    p {
      font-size: 0.75rem;
      margin: 0.5rem 0;
    }
    li {
      font-size: 0.75rem;
    }

  }
`;
const CloseButton = styled.img`
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
  @media (${devices.xs}) {
    top: 0.75rem;
    right: 0.75rem;
  }
`;
export default BuilderDisclaimer;
