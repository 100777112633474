import React from 'react';
import styled, { keyframes } from 'styled-components';
import { colors } from '../styles/theme';

const Loading = ({ className, color }) => {
  return (
    <CubeGrid className={className} color={color}>
      <div className='phase0' />
      <div className='phase1' />
      <div className='phase2' />
      <div className='phase3' />
      <div className='phase1' />
      <div className='phase2' />
      <div className='phase3' />
      <div className='phase4' />
    </CubeGrid>
  );
};
const cubeScale = keyframes`
  0%, 60%, 100% {
    transform: scale3D(1, 1, 1);
  }
  30% {
    transform: scale3D(0, 0, 1);
  }
`;

const CubeGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0.3rem;
  align-content: stretch;
  --base-speed: 0.12s;

  > * {
    width: 2rem;
    height: calc(2rem * 1.65);
    background-color: ${props => props.color || `${colors.blue}59`};
    animation: ${cubeScale} 1.5s infinite ease;
  }

  .phase0 {
    animation-delay: 0s;
  }
  .phase1 {
    animation-delay: var(--base-speed);
  }
  .phase2 {
    animation-delay: calc(var(--base-speed) * 2);
  }
  .phase3 {
    animation-delay: calc(var(--base-speed) * 3);
  }
  .phase4 {
    animation-delay: calc(var(--base-speed) * 4);
  }
`;
export default Loading;
