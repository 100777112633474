import React from 'react';
import styled from 'styled-components';
import { colors, devices } from '../../styles/theme';
import { useDispatch, useSelector } from 'react-redux';
import { changeArea, getAreaLimit, getCurrentArea } from '../../store/build';
import { builderNavList } from '../../utils/dataConfig';

const NavControls = () => {
  const currentArea = useSelector(getCurrentArea);
  const areaLimited = useSelector(getAreaLimit);
  const currentAreaIndex = builderNavList.findIndex(area =>
    currentArea.startsWith(area.name)
  );
  const currentAreaData = builderNavList[currentAreaIndex];
  const nextAreaData = builderNavList[currentAreaIndex + 1];
  const prevAreaData = builderNavList[currentAreaIndex - 1];
  const dispatch = useDispatch();
  return (
    <Container>
      <ControlButton
        onClick={() => dispatch(changeArea(prevAreaData.name))}
        isHidden={currentAreaIndex === 0}
        disabled={
          currentAreaIndex !== 0 && prevAreaData.controlled && areaLimited
        }
      >
        Back
      </ControlButton>
      <ControlButton
        className='next'
        onClick={() => dispatch(changeArea(nextAreaData.name))}
        disabled={nextAreaData.controlled && areaLimited}
      >
        Next
      </ControlButton>
    </Container>
  );
};
const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: 'back next';
  grid-gap: 1rem;

  @media (${devices.s}) {
    grid-gap: 0.5rem;
  }
`;
const ControlButton = styled.button`
  visibility: ${props => (props.isHidden ? 'hidden' : 'visible')};
  grid-area: back;
  width: 100%;
  max-width: var(--button-width);
  height: var(--button-height);
  border: 1px solid ${colors.gray};
  border-radius: 0.375rem;
  margin-bottom: 2.5rem;
  justify-self: start;
  :disabled {
    opacity: 0.4;
    cursor: unset;
    pointer-events: none;
  }
  :hover {
    border-color: ${colors.dark};
  }
  &.next {
    grid-area: next;
    background-color: ${colors.blue};
    color: ${colors.white};
    justify-self: end;
    :hover {
      border-color: unset;
      opacity: 0.85;
    }
  }
  @media (${devices.s}), (${devices.sv}) {
    margin-bottom: 1.5rem;
  }
`;

export default NavControls;
