import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import Nav from '../components/builder/Nav';
import { GlobalStyles } from '../styles/GlobalStyle';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeArea,
  changeAreaLimit,
  getCurrentArea,
  getSelectedCommunity,
  toggleNav,
  updateBuild,
} from '../store/build';
import UnitMedia from '../components/builder/UnitMedia';
import UnitOptions from '../components/builder/UnitOptions';
import InteriorMedia from '../components/builder/InteriorMedia';
import InteriorOptions from '../components/builder/InteriorOptions';
import ExteriorMedia from '../components/builder/ExteriorMedia';
import ExteriorOptions from '../components/builder/ExteriorOptions';
import RoofMedia from '../components/builder/RoofMedia';
import RoofOptions from '../components/builder/RoofOptions';
import BathroomMedia from '../components/builder/BathroomMedia';
import BathroomOptions from '../components/builder/BathroomOptions';
import SummaryMedia from '../components/builder/SummaryMedia';
import SummaryOptions from '../components/builder/SummaryOptions';
import QuickFacts from '../components/builder/QuickFacts';
import SummaryBanner from '../components/builder/SummaryBanner';
import { getBuild } from '../utils/api';
import { colors, devices } from '../styles/theme';
import CommunityLotMedia from '../components/builder/CommunityLotMedia';
import CommunityLotOptions from '../components/builder/CommunityLotOptions';
import ModuleFloorPlan from '../components/builder/ModuleFloorPlan';
import ModuleOptions from '../components/builder/ModuleOptions';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import ModuleBanner from '../components/builder/ModuleBanner';
import { presetBuilder, storeReferralData } from '../utils/helpers';
import Loading from '../components/Loading';
import CommunityInfo from '../components/builder/CommunityInfo';
import BuilderDisclaimerFab from '../components/builder/BuilderDisclaimerFab';
import SEO from '../components/SEO';

const componentMap = {
  'community-lot': {
    media: <CommunityLotMedia />,
    options: <CommunityLotOptions />,
  },
  unit: {
    media: <UnitMedia />,
    options: <UnitOptions />,
  },
  'unit-module': {
    media: <ModuleFloorPlan />,
    options: <ModuleOptions />,
    banner: <ModuleBanner />,
  },
  interior: {
    media: <InteriorMedia />,
    options: <InteriorOptions />,
  },
  exterior: {
    media: <ExteriorMedia />,
    options: <ExteriorOptions />,
  },
  roof: {
    media: <RoofMedia />,
    options: <RoofOptions />,
  },
  bathroom: {
    media: <BathroomMedia />,
    options: <BathroomOptions />,
  },
  summary: {
    media: <SummaryMedia />,
    options: <SummaryOptions />,
    banner: <SummaryBanner />,
  },
};

const removeParams = () => navigate('/builder/', { replace: true });

const BuilderPage = ({ location }) => {
  // process path params to update state if necessary
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (location.search) {
      // parse search params
      const params = new URLSearchParams(location.search);
      storeReferralData(params);
      const source = params.get('src');
      switch (source) {
        case 'build': {
          const buildId = params.get('id');
          if (buildId?.length === 12) {
            // link contain build id, restore build
            setLoading(true);
            getBuild(buildId)
              .then(build => {
                dispatch(updateBuild(build));
                dispatch(toggleNav(false));
                setLoading(false);
              })
              .catch(() => {
                // invalid build id
                setLoading(false);
              });
          }
          break;
        }
        case 'communities': {
          presetBuilder(dispatch, {
            community: params.get('c'),
            lot: params.get('l'),
            intent: params.get('i'),
          });
          dispatch(changeArea('unit'));
          removeParams();
          break;
        }
        case 'floorPlans': {
          presetBuilder(dispatch, {
            community: params.get('c'),
            lot: params.get('l'),
            intent: params.get('i'),
            unit: params.get('u'),
          });
          dispatch(changeArea('community-lot'));
          removeParams();
          break;
        }
        case 'home': {
          presetBuilder(dispatch, {
            community: params.get('c'),
            lot: params.get('l'),
            intent: params.get('i'),
          });
          dispatch(changeArea('community-lot'));
          removeParams();
          break;
        }
        case 'ownVsRent': {
          presetBuilder(dispatch, { intent: params.get('i') });
          dispatch(changeArea('community-lot'));
          removeParams();
          break;
        }
        default: {
          // invalid search params
        }
      }
    }
  }, []);
  const currentArea = useSelector(getCurrentArea);
  const selectedCommunity = useSelector(getSelectedCommunity);

  // determine drag drop backend
  const [device, setDevice] = useState(null);
  useEffect(() => {
    typeof window !== 'undefined' &&
    ('ontouchstart' in window ||
      navigator.MaxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0)
      ? setDevice('touch')
      : setDevice('mouse');
  }, []);

  return (
    <>
      <SEO title='Build Your Home' />
      <GlobalStyles />
      <Container>
        <NavContainer>
          <Nav />
        </NavContainer>
        {device && (
          <DndProvider
            backend={device === 'touch' ? TouchBackend : HTML5Backend}
          >
            <MediaContainer>
              {componentMap[currentArea].media}
              {currentArea !== 'unit-module' && (
                <CommunityInfo
                  community={selectedCommunity}
                  showMap={
                    currentArea === 'community-lot' || currentArea === 'summary'
                  }
                />
              )}
              <BuilderDisclaimerFab />
            </MediaContainer>
            <OptionsContainer>
              {componentMap[currentArea].options}
            </OptionsContainer>
          </DndProvider>
        )}
        <BannerContainer>
          {componentMap[currentArea].banner || <QuickFacts />}
        </BannerContainer>
        {isLoading && (
          <Overlay>
            <Loading color={colors.white} />
          </Overlay>
        )}
      </Container>
    </>
  );
};

const Container = styled.section`
  height: 100vh;
  display: grid;
  //grid-template-columns: 1.8fr 1fr;
  grid-template-columns: 1fr 32rem; // ?duplicate in ModuleBuilder
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    'nav   nav'
    'media options'
    'media banner';
  @media (${devices.m}) {
    grid-template-columns: 1fr 27rem;
  }
  @media (${devices.s}) {
    grid-template-columns: 1fr 22rem;
  }
  @media (${devices.xs}) {
    height: auto;
    grid-template-rows: min-content minmax(50vh, min-content) auto auto;
    grid-template-areas:
      'nav   nav'
      'media media'
      'options options'
      'banner banner';
  }
`;
const NavContainer = styled.nav`
  grid-area: nav;
  display: grid;
  justify-items: center;
  align-items: center;
  @media (${devices.xs}) {
    position: sticky;
    top: 0;
    z-index: 5;
    background-color: ${colors.white}D9;
    backdrop-filter: blur(1rem);
  }
`;
const MediaContainer = styled.div`
  grid-area: media;
  position: relative;
`;
export const OptionsContainer = styled.div`
  grid-area: options;
  overflow: auto;
  // make scroll bar an overlay in Chrome
  overflow-y: overlay;

  ::-webkit-scrollbar {
    width: 1rem;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${colors.blue}66;
    border-radius: 0.375rem;
  }

  ::-webkit-scrollbar-track {
    background-color: ${colors.blue}33;
  }
`;
const BannerContainer = styled.div`
  grid-area: banner;
  //padding: var(--builder-grid-banner-padding);
`;
const Overlay = styled.div`
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(0.5rem);
  width: 100%;
  height: 100%;
  z-index: 10;
  display: grid;
  justify-content: center;
  align-content: center;
`;
const Spinner = styled.div`
  display: inline-block;
  width: 80px;
  height: 80px;

  &:after {
    content: ' ';
    display: block;
    width: 5rem;
    height: 5rem;
    margin: auto;
    border-radius: 50%;
    border: 0.5rem solid;
    border-color: white transparent white transparent;
    animation: lds-dual-ring 1s linear infinite;
  }

  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
export default BuilderPage;
