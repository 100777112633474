import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';
import {
  getLoadedAssets,
  getSelectedUnit,
  setLoadedAsset,
} from '../../store/build';
import { GatsbyImage } from 'gatsby-plugin-image';
import { bounce, BuilderMediaContainer } from '../../styles/shared';
import Loading from '../Loading';
import { colors, devices } from '../../styles/theme';
import { AiFillLeftCircle } from '@react-icons/all-files/ai/AiFillLeftCircle';
import SliderToggle from '../SliderToggle';

const UnitMedia = () => {
  const data = useStaticQuery(graphql`
    query getUnitImages {
      allFile(filter: { name: { regex: "/^unit/" } }) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(quality: 70, height: 1080, placeholder: NONE)
          }
        }
      }
      background: file(name: { eq: "unit-background" }) {
        childImageSharp {
          gatsbyImageData(quality: 1, height: 400, placeholder: NONE)
        }
      }
    }
  `);
  const selectedUnit = useSelector(getSelectedUnit);
  const background = data.background;

  const [currentImages, setImages] = useState([]);
  const [showAltView, setAltView] = useState(false);
  useEffect(() => {
    const images = data.allFile.nodes
      .filter(({ name }) => name.startsWith('unit-' + selectedUnit))
      .sort((a, b) => (a.name > b.name ? 1 : -1));
    setImages(images);
    setAltView(false);
    !loadedAssets[selectedUnit] && setLoading(true);
  }, [selectedUnit]);

  const hasAltView = currentImages.length > 1;

  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const loadedAssets = useSelector(getLoadedAssets);
  const handleLoad = unitType => {
    !loadedAssets[unitType] && dispatch(setLoadedAsset(unitType));
    isLoading && setLoading(false);
  };

  return (
    <BuilderMediaContainer style={{ display: 'grid' }}>
      <>
        {isLoading && <Loader />}
        <GatsbyImage
          image={background.childImageSharp.gatsbyImageData}
          style={{
            height: '100%',
            gridArea: '1 / 1',
            filter: 'opacity(20%)',
          }}
          alt='floor plan background'
        />
        {currentImages.length > 0 && (
          <NextViewButton
            onClick={() => setAltView(prev => !prev)}
            hasAltView={hasAltView}
          >
            <div
              style={{ ...imageContainerStyle, zIndex: showAltView ? 0 : 1 }}
            >
              <GatsbyImage
                image={currentImages[0].childImageSharp.gatsbyImageData}
                objectFit='contain'
                loading='eager'
                alt={selectedUnit + ' floor plan'}
                style={imageStyle}
                onLoad={() => handleLoad(selectedUnit)}
              />
            </div>
            <div
              style={{ ...imageContainerStyle, zIndex: showAltView ? 1 : 0 }}
            >
              <GatsbyImage
                image={
                  currentImages[currentImages.length - 1].childImageSharp
                    .gatsbyImageData
                }
                objectFit='contain'
                loading='eager'
                alt={selectedUnit + ' floor plan'}
                style={imageStyle}
              />
            </div>
            {hasAltView && (
              <DayNightToggle
                current={showAltView ? 'night' : 'day'}
                right={{ title: 'Sleep', value: 'night' }}
                left={{ title: 'Day', value: 'day' }}
                onClick={e => e.stopPropagation()}
              />
            )}
          </NextViewButton>
        )}
      </>
    </BuilderMediaContainer>
  );
};
const imageContainerStyle = {
  // gridArea: '1 / 1',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translateX(-50%) translateY(-50%)',
  height: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
const imageStyle = {
  maxHeight: '100%',
  maxWidth: '100%',
};
const NextViewButton = styled.button`
  justify-self: center;
  align-self: center;
  grid-area: 1 / 1;
  position: relative;
  width: 100%;
  height: 100%;
  //display: grid;
  //align-items: center;
  //justify-items: center;
  cursor: ${props => (props.hasAltView ? 'pointer' : 'default')};
  //:hover {
  //  > :last-child {
  //    opacity: 1;
  //  }
  //}
`;
const Loader = styled(Loading)`
  grid-area: 1 / 1;
  justify-self: center;
  align-self: center;
  z-index: 1;
`;
const NavContainer = styled.div`
  cursor: default;
  position: absolute;
  bottom: 8%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  background-color: ${colors.white};
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  grid-gap: 1rem;
  height: 3.5rem;
  padding: 0.3rem;
  border-radius: 1.75rem;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.4));
  //opacity: 0.25;
  transition: opacity 0.2s;

  @media (${devices.xs}) {
    height: 2.5rem;
    border-radius: 1.25rem;
  }
`;
const LeftArrowCircle = styled(AiFillLeftCircle)`
  cursor: pointer;
  width: 2.85rem;
  height: 2.85rem;
  @media (${devices.xs}) {
    width: 1.9rem;
    height: 1.9rem;
  }
`;
const RightArrowCircle = styled(LeftArrowCircle)`
  transform: rotateZ(180deg);
`;
const NavItemContainer = styled.ul`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-gap: 0.5rem;
`;
const NavItem = styled.li`
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  background-color: ${colors.gray};
  cursor: pointer;

  &[aria-current='true'] {
    background-color: ${colors.blue};
  }
  transition: background-color 0.2s;
`;
const DayNightToggle = styled(SliderToggle)`
  position: absolute;
  bottom: 8%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
`;
export default UnitMedia;
