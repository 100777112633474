import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { RadioButton } from '../styles/shared';
import { colors } from '../styles/theme';

const ListRadio = ({ items, currentItem, action }) => {
  const dispatch = useDispatch();
  const handleClick = option => {
    dispatch(action(option));
  };
  return (
    <>
      <Container>
        {items.list.map(item => (
          <li
            key={item}
            onClick={() => handleClick(item)}
            aria-current={item === currentItem}
          >
            <RadioButton />
            <p>{items[item].title} Bathroom</p>
          </li>
        ))}
      </Container>
    </>
  );
};

const Container = styled.ul`
  display: grid;
  row-gap: 1.125rem;
  justify-content: start;

  li {
    cursor: pointer;
    display: grid;
    grid-auto-flow: column;
    column-gap: 0.5rem;
    align-items: center;

    &[aria-current='true'] {
      cursor: default;

      button:after {
        background-color: ${colors.blue};
      }
    }
  }
`;
export default ListRadio;
