import React, { useState } from 'react';
import styled from 'styled-components';
import { colors, devices, fontWeights } from '../../styles/theme';
import Modal from '../Modal';
import BuilderDisclaimer from '../modals/BuilderDisclaimer';

const BuilderDisclaimerFab = () => {
  const [modal, setModal] = useState('disclaimer');
  const handleClose = () => setModal(null);

  return (
    <>
      <Container>
        <DisclaimerFab onClick={() => setModal('disclaimer')}>?</DisclaimerFab>
        {modal === 'disclaimer' && (
          <Modal
            anchorStyle={{ width: '100%', height: '100%' }}
            handleClose={handleClose}
          >
            <BuilderDisclaimer handleClose={handleClose} />
          </Modal>
        )}
      </Container>
    </>
  );
};

const Container = styled.div`
  z-index: 5;
  position: absolute;
  bottom: min(6%, 3.5rem);
  left: min(6%, 3.5rem);
  @media (${devices.xs}) {
    bottom: 3%;
    left: 3%;
    --item-height: 3.5rem;
  }
`;
const DisclaimerFab = styled.div`
  width: 4.5rem;
  height: 4.5rem;
  line-height: 1.4;
  border-radius: 50%;
  padding: 0.5rem;
  text-align: center;
  background-color: ${colors.white};
  box-shadow: 0 2px 8px -1px rgba(0, 0, 0, 0.5);
  font-size: 3rem;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: ${colors.blue};
    color: ${colors.white};
  }
  @media (${devices.xs}) {
    width: 3.5rem;
    height: 3.5rem;
    line-height: 1.75;
    font-size: 2rem;
    padding: 0.25rem;
  }
`;
export default BuilderDisclaimerFab;
